<!-- Item wrapper -->
<div class="e" [ngClass]="item.classes?.wrapper">
  <div class="vertical-navigation-item">
    <!-- Icon -->
    <ng-container *ngIf="item.icon">
      <mat-icon
        class="vertical-navigation-item-icon"
        [ngClass]="item.classes?.icon"
        [svgIcon]="item.icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="vertical-navigation-item-title-wrapper">
      <div class="vertical-navigation-item-title">
        <span [ngClass]="item.classes?.title">
          {{ item.title }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="vertical-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="vertical-navigation-item-badge">
        <div
          class="vertical-navigation-item-badge-content"
          [ngClass]="item.badge.classes">
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngFor="let item of item.children">
  <!-- Basic items -->
  <vex-label-basic-item [item]="item"></vex-label-basic-item>
</ng-container>
