import { Component, effect } from '@angular/core';
import { NetworkService } from './network-service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'vex-network-warning',
  template: `
    <div *ngIf="isOffline" class="network-warning">
      <div class="flex flex-row items-center gap-4 font-semibold">
        <mat-icon svgIcon="mat_solid:wifi_off"></mat-icon> Network connection
        lost
      </div>
    </div>

    <div *ngIf="!isOffline && showMessage" class="network-success">
      <div class="flex flex-row items-center gap-4 font-semibold">
        <mat-icon svgIcon="mat:check"></mat-icon> Network connection back
      </div>
    </div>

    <div *ngIf="isSlowNetwork && !isOffline" class="network-slow-warning">
      <div class="flex flex-row items-center gap-4 font-semibold">
        <mat-icon svgIcon="mat_outline:network_check"></mat-icon> Slow network
        connection detected
      </div>
    </div>
  `,
  styles: [
    `
      .network-warning-overly-message {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      .network-warning {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: var(--vex-sidenav-background);
        transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        padding: 10px 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
      }
      .network-success {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: var(--cqa-background-primary);
        transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        padding: 10px 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
      }

      .network-slow-warning {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: orange;
        padding: 10px 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
      }
    `
  ],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class NetworkWarningComponent {
  isOffline = !window.navigator.onLine; // Initialize based on current connection status
  showMessage = false;
  private wasOffline = !window.navigator.onLine; // Track the initial network state
  isSlowNetwork = false; // New variable to track slow network status
  constructor(private networkService: NetworkService) {
    // Reactively track network changes using the signal's value
    effect(() => {
      const connected = this.networkService.connected();

      this.isOffline = !connected;

      if (connected && this.wasOffline) {
        // Only show the message if previously offline
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 3000);
      }

      // Update wasOffline to track the network state for the next check
      this.wasOffline = !connected;
    });

    // Detect slow network connection
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      // Check for slow network types
      this.isSlowNetwork =
        connection.downlink < 0.5 ||
        ['2g', 'slow-2g'].includes(connection.effectiveType);

      // Add event listener to monitor changes in network speed
      connection.addEventListener('change', () => {
        this.isSlowNetwork =
          connection.downlink < 0.5 ||
          ['2g', 'slow-2g'].includes(connection.effectiveType);
      });
    }
  }
}
