<!-- Item wrapper -->
<div
  class="vertical-navigation-item-wrapper cursor-pointer"
  [ngClass]="item.classes?.wrapper"
  (click)="onItemClicked(item)">
  <!-- Item  -->
  <ng-container>
    <div class="vertical-navigation-item" [matTooltip]="item.tooltip || ''">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<!-- Item template -->
<ng-template #itemTemplate>
  <!-- Icon -->
  <ng-container *ngIf="item.icon">
    <mat-icon
      class="vertical-navigation-item-icon"
      [ngClass]="item.classes?.icon"
      [svgIcon]="item.icon"></mat-icon>
  </ng-container>

  <ng-container *ngIf="item.image">
    <img
      class="vertical-navigation-item-icon"
      [ngClass]="item.classes?.image"
      [src]="item.image"
      alt="image" />
  </ng-container>
  <!-- Title & Subtitle -->
  <div class="vertical-navigation-item-title-wrapper">
    <div class="vertical-navigation-item-title">
      <span [ngClass]="item.classes?.title">
        {{ item.title }}
      </span>
    </div>
    <ng-container *ngIf="item.subtitle">
      <div class="vertical-navigation-item-subtitle">
        <span [ngClass]="item.classes?.subtitle">
          {{ item.subtitle }}
        </span>
      </div>
    </ng-container>
  </div>

  <!-- Badge -->
  <ng-container *ngIf="item.badge">
    <div class="vertical-navigation-item-badge">
      <div
        class="vertical-navigation-item-badge-content"
        [ngClass]="item.badge.classes">
        {{ item.badge.title }}
      </div>
    </div>
  </ng-container>
</ng-template>
