import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { UserAuthenticationService } from '../navigation/user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public userCheck: UserAuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Check if user is already logged in
    if (this.isUserAuthenticated()) {
      if (this.userCheck.checkUser()) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/home']);
      }
      return false;
    }
    return true;
  }
  private isUserAuthenticated(): boolean {
    const userRole = localStorage.getItem('role');
    if (userRole) {
      return true;
    }
    return false;
  }
}
