import { Injectable } from '@angular/core';
import { ProgramConfig } from 'src/app/core/module/program-config-interface';

@Injectable({
  providedIn: 'root'
})
export class LabelPrinterService {
  constructor() {}

  customStylesPrint = (width: string, height: string) => `
  <style>
    @media print {
      @page {
        size: ${width}mm ${height}mm; /* Custom label size */
        margin: 0; /* Removes default browser margins */
      }
      body {
        margin: 0;
        padding: 0;
        width: ${width}mm;
        height: ${height}mm;
        transform: scale(1);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; /* Prevent overflow onto other pages */

      }
      .label-container {
        width: ${width}mm;
        height: ${height}mm;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; /* Prevent content from going outside */
        position: relative;
        box-sizing:border-box;
        border: 1px dashed #000; /* Add a border for visualization */

      }
    }
  </style>
`;
  convertMmToPx(mm: number): string {
    const dpi = 96; // Default DPI for web
    const mmToInches = mm / 25.4; // Convert mm to inches
    return (mmToInches * dpi).toString(); // Convert inches to pixels
  }
  onPrintTheContainerLabel(labelConfig: any, labelPreviewElement: any) {
    const labelWidth = labelConfig?.label['width.mm'].toString();
    const labelHeight = labelConfig?.label['height.mm'].toString();

    const printContent = labelPreviewElement;
    const printWindow = window.open('', '_blank', 'width=800,height=600');

    if (printWindow && printContent) {
      const styles = this.customStylesPrint(labelWidth, labelHeight);
      const htmlContent = printContent;

      // Write the HTML content and custom styles to the print window
      printWindow.document.open();
      printWindow.document.write(`
          <html>
          <head>
            <title>Print Label</title>
            ${styles}
          </head>
          <body>
            <div class="label-container">
              ${htmlContent}
            </div>
          </body>
          </html>
        `);
      printWindow.document.close();

      // Wait for all images in the print window to load
      const images = printWindow?.document?.images;
      let loadedImagesCount = 0;

      if (images && images?.length > 0) {
        // Track image loading
        for (let i = 0; i < images.length; i++) {
          images[i].onload = () => {
            loadedImagesCount++;
            // If all images have loaded, trigger print
            if (loadedImagesCount === images.length) {
              printWindow.print();
            }
          };
          images[i].onerror = () => {
            // If an image fails to load, consider it as loaded to not block printing
            loadedImagesCount++;
            if (loadedImagesCount === images.length) {
              printWindow.print();
            }
          };
        }
      } else {
        // If there are no images, print immediately
        printWindow.print();
      }
    }
  }
}
