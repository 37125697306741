<!-- <div class="ll-setting-row">
  <table class="input">
    <tr>
      <td colspan="2">
        <div style="text-align: right">
          <div class="btn-group btn-sm ll-setting-row" role="group" style="padding-right: 0; padding-left: 0">
            <button type="button" class="btn btn-default btn-sm"
                    *ngIf="showUnderline"
                    [class.active]="fontSettings && fontSettings['text-decoration']"
                    (click)="change('text-decoration', fontSettings && fontSettings['text-decoration'] ? undefined : 'underline')"
            ><ll-icon type="font-underline" [size]="18"></ll-icon></button>
            <button type="button" class="btn btn-default btn-sm"
                    [class.active]="fontSettings && fontSettings['font-weight']"
                    (click)="change('font-weight', fontSettings && fontSettings['font-weight'] ? undefined : 'bold')"
            ><ll-icon type="font-bold" [size]="18"></ll-icon></button>
            <button type="button" class="btn btn-default btn-sm"
                    [class.active]="fontSettings && fontSettings['font-style']"
                    (click)="change('font-style', fontSettings && fontSettings['font-style'] ? undefined : 'italic')"
            ><ll-icon type="font-italic" [size]="18"></ll-icon></button>
          </div>
          <div class="btn-group btn-sm ll-setting-row" role="group" *ngIf="!minimal">
            <button type="button" class="btn btn-default btn-sm"
                    [class.active]="fontSettings && fontSettings['text-align'] === 'left'"
                    (click)="change('text-align', fontSettings && fontSettings['text-align'] === 'left' ? undefined : 'left')"
            ><ll-icon type="align-left" [size]="18"></ll-icon></button>
            <button type="button" class="btn btn-default btn-sm"
                    [class.active]="fontSettings && fontSettings['text-align'] === 'center'"
                    (click)="change('text-align', fontSettings && fontSettings['text-align'] === 'center' ? undefined : 'center')"
            ><ll-icon type="align-center" [size]="18"></ll-icon></button>
            <button type="button" class="btn btn-default btn-sm"
                    [class.active]="fontSettings && fontSettings['text-align'] === 'right'"
                    (click)="change('text-align', fontSettings && fontSettings['text-align'] === 'right' ? undefined : 'right')"
            ><ll-icon type="align-right" [size]="18"></ll-icon></button>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'Font' | translate }}:</td>
      <td>
        <select style="width: 100%" class="form-control input-sm" (change)="change('font-family', $event.target.value)">
          <option value="" *ngIf="allowEmptyFontFamily"></option>
          <option *ngIf="fontSettings && fontSettings['font-family'] && fontFamily.indexOf(fontSettings['font-family']) === -1" [value]="fontSettings['font-family']" [selected]="true">{{ fontSettings['font-family'] }}</option>
          <option *ngFor="let family of fontFamily" [value]="family" [selected]="fontSettings && family === fontSettings['font-family']">{{ family }}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>{{ 'Font size' | translate }}:</td>
      <td>
        <select class="form-control input-sm" style="width: 60px;" (change)="change('font-size.pt', $event.target.value, true)">
          <option value=""></option>
          <option *ngFor="let size of fontSizes" [value]="size" [selected]="fontSettings && size === fontSettings['font-size.pt']">{{ size }}</option>
        </select>
      </td>
    </tr>
    <tr *ngIf="!minimal">
      <td>{{ 'Line spacing' | translate }}:</td>
      <td>
        <select class="form-control input-sm" style="width: 60px;" (change)="change('line-height', $event.target.value)">
          <option value=""></option>
          <option *ngFor="let size of lineHeight" [value]="size" [selected]="fontSettings && size == fontSettings['line-height']">{{ size }}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>{{ 'Transform' | translate }}:</td>
      <td>
        <select class="form-control input-sm" (change)="change('text-transform', $event.target.value)">
          <option value=""></option>
          <option *ngFor="let value of textTransform" [value]="value" [selected]="fontSettings && value === fontSettings['text-transform']">{{ value | translate }}</option>
        </select>
      </td>
    </tr>
  </table>
</div> -->

<div class="flex flex-col gap-3 px-3">
  <div class="flex flex-col">
    <div class="text-[#909090]">{{ "Font" | translate | translate }}</div>
    <div class="flex gap-4 items-center">
      <div class="cursor-pointer">
        <!-- (change)="change('font-family', $event.target.value)" -->
        <select
          class="cursor-pointer border border-[#909090] rounded-[4px]"
          (change)="change('font-family', $event)">
          <option value="" *ngIf="allowEmptyFontFamily">-Select Font-</option>
          <option
            *ngIf="
              fontSettings &&
              fontSettings['font-family'] &&
              fontFamily.indexOf(fontSettings['font-family']) === -1
            "
            [value]="fontSettings['font-family']"
            [selected]="true">
            {{ fontSettings["font-family"] }}
          </option>
          <option
            *ngFor="let family of fontFamily"
            [value]="family"
            [selected]="fontSettings && family === fontSettings['font-family']">
            {{ family }}
          </option>
        </select>
      </div>
      <div
        class="btn-group flex items-center btn-sm ll-setting-row"
        role="group"
        *ngIf="!minimal">
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          [class.active]="fontSettings && fontSettings['text-align'] === 'left'"
          (click)="
            change(
              'text-align',
              fontSettings && fontSettings['text-align'] === 'left'
                ? undefined
                : 'left'
            )
          ">
          <mat-icon
            [ngClass]="{
              'text-black': selectedTextAlign == 'left'
            }"
            class="max-h-1 h-20px mat-custom-height"
            svgIcon="mat_outline:format_align_left"></mat-icon>
          <!-- <ll-icon type="align-left" [size]="18"></ll-icon> -->
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          [class.active]="
            fontSettings && fontSettings['text-align'] === 'center'
          "
          (click)="
            change(
              'text-align',
              fontSettings && fontSettings['text-align'] === 'center'
                ? undefined
                : 'center'
            )
          ">
          <mat-icon
            [ngClass]="{
              'text-black': selectedTextAlign == 'center'
            }"
            class="max-h-1 h-20px mat-custom-height"
            svgIcon="mat_outline:format_align_center"></mat-icon>
          <!-- <ll-icon type="align-center" [size]="18"></ll-icon> -->
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          [class.active]="
            fontSettings && fontSettings['text-align'] === 'right'
          "
          (click)="
            change(
              'text-align',
              fontSettings && fontSettings['text-align'] === 'right'
                ? undefined
                : 'right'
            )
          ">
          <mat-icon
            [ngClass]="{
              'text-black': selectedTextAlign == 'right'
            }"
            class="max-h-1 h-20px mat-custom-height"
            svgIcon="mat_outline:format_align_right"></mat-icon>
          <!-- <ll-icon type="align-right" [size]="18"></ll-icon> -->
        </button>
      </div>
      <div
        class="btn-group flex items-center btn-sm ll-setting-row"
        role="group">
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          *ngIf="showUnderline"
          [ngClass]="{
            'text-black': fontSettings && fontSettings['text-decoration']
          }"
          (click)="
            change(
              'text-decoration',
              fontSettings && fontSettings['text-decoration']
                ? undefined
                : 'underline'
            )
          ">
          <mat-icon
            *ngIf="!selectedTextDecoration"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-underline"></mat-icon>
          <mat-icon
            *ngIf="selectedTextDecoration == 'underline'"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-black-underline"></mat-icon>

          <!-- <ll-icon type="font-underline" [size]="18"></ll-icon> -->
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          [class.active]="fontSettings && fontSettings['font-weight']"
          (click)="
            change(
              'font-weight',
              fontSettings && fontSettings['font-weight'] ? undefined : 'bold'
            )
          ">
          <mat-icon
            *ngIf="!selectedTextWeight"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-bold"></mat-icon>
          <mat-icon
            *ngIf="selectedTextWeight == 'bold'"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-black-bold"></mat-icon>
          <!-- <ll-icon type="font-bold" [size]="18"></ll-icon> -->
        </button>
        <button
          type="button"
          class="btn btn-default btn-sm text-[#909090]"
          [class.active]="fontSettings && fontSettings['font-style']"
          (click)="
            change(
              'font-style',
              fontSettings && fontSettings['font-style'] ? undefined : 'italic'
            )
          ">
          <mat-icon
            *ngIf="!selectedFontStyle"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-italic"></mat-icon>
          <mat-icon
            *ngIf="selectedFontStyle == 'italic'"
            class="max-h-1 h-15px mat-custom-height"
            svgIcon="heroicons_outline:font-black-italic"></mat-icon>
          <!-- <ll-icon type="font-italic" [size]="18"></ll-icon> -->
        </button>
      </div>
    </div>
  </div>
  <div class="flex gap-4">
    <div class="flex flex-col w-full">
      <div class="text-[#909090]">{{ "Font size" | translate }}</div>
      <div class="cursor-pointer">
        <select
          class="form-control input-sm cursor-pointer border border-[#909090] rounded-[4px]"
          (change)="change('font-size.pt', $event, true)">
          <option value="">-Font size-</option>
          <option
            *ngFor="let size of fontSizes"
            [value]="size"
            [selected]="fontSettings && size === fontSettings['font-size.pt']">
            {{ size }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div class="text-[#909090]">{{ "Transform" | translate }}</div>
      <div class="cursor-pointer">
        <select
          class="form-control input-sm cursor-pointer border border-[#909090] rounded-[4px]"
          (change)="change('text-transform', $event)">
          <option value="">0</option>
          <option
            *ngFor="let value of textTransform"
            [value]="value"
            [selected]="
              fontSettings && value === fontSettings['text-transform']
            ">
            {{ value | translate }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-col w-full" *ngIf="!minimal">
      <div class="text-[#909090]">{{ "Line spacing" | translate }}</div>
      <div class="cursor-pointer">
        <select
          class="form-control input-sm cursor-pointer border border-[#909090] rounded-[4px]"
          (change)="change('line-height', $event)">
          <option value="">0</option>
          <option
            *ngFor="let size of lineHeight"
            [value]="size"
            [selected]="fontSettings && size == +fontSettings['line-height']">
            {{ size }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
