import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { AppSettingsService } from '../app-settings.service';
import { RolesService } from 'src/app/pages/master-data/roles/roles.service';
import { MenusService } from 'src/app/pages/master-data/menus/menus.service';
import { ProgramConfigService } from 'src/app/pages/master-data/program-config/program-config.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { UserAuthenticationService } from './user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);
  role: any;
  path: string;
  userInfo: any;
  programList: any[] = [];
  programData: any;
  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }
  menusPermissionResp: any;
  rolePermission: any[] = [];

  constructor(
    private readonly layoutService: VexLayoutService,
    private appsettingsService: AppSettingsService,
    private rolesService: RolesService,
    private menusService: MenusService,
    private programConfigService: ProgramConfigService,
    public userCheck: UserAuthenticationService,
    private dataService: DataService
  ) {
    this.userInfo = this.appsettingsService.userData().userDetails;
    this.role = localStorage.getItem('role');
    this.path = this.role == 'ROLE_ADMIN' ? 'admin' : 'subadmin';
    // this.loadNavigation();
    // this.get();
  }
  async getRolePermission() {
    if (
      this.userInfo &&
      this.userInfo?.permissions &&
      this.userInfo?.permissions?.menuRoleId
    ) {
      try {
        let roleID = this.userInfo.permissions.menuRoleId;

        this.menusPermissionResp = await firstValueFrom(
          this.rolesService.getByRolesId(roleID)
        );
        this.rolePermission = this.menusPermissionResp.permissions;
      } catch (err) {
        console.error(err);
      }
    }
  }

  loadNavigation(): void {
    this._items.next([
      {
        id: 'dashboard',
        type: 'link',
        label: 'Home',
        icon: 'mat:dashboard',
        route: `/dashboard`,
        routerLinkActiveOptions: { exact: true }
      },
      {
        id: 'openActions',
        type: 'link',
        label: 'Action Items',
        icon: 'heroicons_solid:actionItems',
        route: `/action-items`
      },
      {
        type: 'dropdown',
        label: 'Companies Category',
        icon: 'mat:account_balance',
        children: [
          {
            id: 'company',
            type: 'link',
            label: 'All Companies',
            route: `/company`
          },
          {
            id: 'formulator',
            type: 'link',
            label: 'Formulator Company Contact',
            route: `/formulator`
          },
          {
            id: 'licensee',
            type: 'link',
            label: 'Licensee Company Contacts',
            icon: 'mat:tactic',
            route: `/licensee`
          },
          {
            id: 'license-agreement',
            type: 'link',
            label: 'License Agreements & Amendments',
            icon: 'mat:scale',
            route: `/license-agreement`
          },
          {
            id: 'company_completed_forms_documents',
            type: 'link',
            label: 'Completed Forms & Documents',
            route: `/company_completed_forms_documents`,
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Formulation & Sample Management Category',
        icon: 'mat:notes',
        children: [
          {
            id: 'formulation?type=SubmittedFormulations',
            type: 'link',
            label: 'Submitted Formulations',
            route: `/formulation`,
            routerLinkActiveOptions: { exact: true },
            queryParams: { type: 'SubmittedFormulations' }
          },
          {
            id: 'formulation?type=FormulationData',
            type: 'link',
            label: 'Formulation Data',
            route: `/formulation`,
            queryParams: { type: 'FormulationData' }
          },
          {
            id: 'sample?type=SubmittedSamples',
            type: 'link',
            label: 'Submitted Samples',
            icon: 'mat:tactic',
            route: `/sample`,
            queryParams: { type: 'SubmittedSamples' }
          },
          {
            id: 'sample?type=SampleData',
            type: 'link',
            label: 'Sample Data',
            icon: 'mat:scale',
            route: `/sample`,
            queryParams: { type: 'SampleData' }
          },
          {
            id: 'sample_data_reports',
            type: 'link',
            label: 'Sample Data Reports',
            route: `/sample`,
            queryParams: { type: 'SampleDataReports' }
          },
          {
            id: 'supplier_declarations',
            type: 'link',
            label: 'Supplier Declarations',
            route: `/supplier_declarations`
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Branding Management Category',
        icon: 'mat:branding_watermark',
        children: [
          {
            id: 'brand?type=brands',
            type: 'link',
            label: 'Brands',
            route: `/brand`,
            // routerLinkActiveOptions: { exact: true },
            queryParams: { type: 'brands' }
          },
          {
            id: 'product_brand_names?type=brandNames',
            type: 'link',
            label: 'Product Brand Names',
            route: `/product_brand_names`,
            queryParams: { type: 'brandNames' }
          },
          {
            id: 'product-label-list?type=product-label-list',
            type: 'link',
            label: 'Product Labeling',
            icon: 'mat:tactic',
            route: `/product-label-list`,
            queryParams: { type: 'product-label-list' }
          },
          {
            id: 'marketing_materials?type=marketingMaterials',
            type: 'link',
            label: 'Marketing Materials',
            icon: 'mat:scale',
            route: `/marketing_materials`,
            queryParams: { type: 'marketingMaterials' }
          },
          {
            id: 'brand-registration-certificates?type=BrandRegistrationCertificates',
            type: 'link',
            label: 'Brand Registration Certificates',
            route: `/brand-registration-certificates`,
            queryParams: { type: 'BrandRegistrationCertificates' }
          },
          {
            id: 'website_brands_list?type=website_brands_list',
            type: 'link',
            label: 'Website Brands List',
            route: `/website_brands_list`,
            queryParams: { type: 'WebsiteBrandList' }
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Renewal & Audit Management Category',
        icon: 'mat:payment',
        children: [
          {
            id: 'renewalNotices',
            type: 'link',
            label: 'Renewal Notices',
            route: `/renewalNotices`
          },
          {
            id: 'audit/expiration_termination_notices',
            type: 'link',
            label: 'Expiration / Termination Notices',
            route: `/expiration_termination_notices`
          },
          {
            id: 'audit/sample?type=production_sample_intake_tracking',
            type: 'link',
            label: 'Production Sample Intake Tracking',
            icon: 'mat:tactic',
            route: `/audit/sample`,
            queryParams: { type: 'production_sample_intake_tracking' }
          },
          {
            id: 'audit/sample?type=fieldAuditSample',
            type: 'link',
            label: 'Field Audit Sample Selection Generator',
            icon: 'mat:scale',
            route: `/audit/sample`,
            queryParams: { type: 'fieldAuditSample' }
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Financial Management Category',
        icon: 'mat:payments',
        children: [
          {
            id: 'payments?type=LicensingProgramFees',
            type: 'link',
            label: 'Licensing Program Fees',
            route: `/payments`,
            queryParams: { type: 'LicensingProgramFees' }
          },
          {
            id: 'quotations',
            type: 'link',
            label: 'Quotes',
            route: `/quotations`
          },
          {
            id: 'invoices',
            type: 'link',
            label: 'Invoices',
            icon: 'mat:tactic',
            route: `/invoices`
          },
          {
            id: 'payments?type=FinancialData',
            type: 'link',
            label: 'Financial Data',
            icon: 'mat:scale',
            route: `/payments`,
            queryParams: { type: 'FinancialData' }
          },
          {
            id: 'payments?type=FinancialReports',
            type: 'link',
            label: 'Financial Reports',
            route: `/payments`,
            queryParams: { type: 'FinancialReports' }
          }
        ]
      },
      // {
      //   id: 'company',
      //   type: 'link',
      //   label: 'Company',
      //   icon: 'mat:account_balance',
      //   route: `/company`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'license_agreement',
      //   type: 'link',
      //   label: 'License agreement',
      //   route: `/license_agreement`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'payments',
      //   type: 'link',
      //   label: 'Payments',
      //   route: `/payments`,
      //   icon: 'mat:payment',
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'product_registration',
      //   type: 'link',
      //   label: 'Product Registration',
      //   route: `/Product Registration`,
      //   routerLinkActiveOptions: { exact: true }
      // },

      // {
      //   id: 'audit_management',
      //   type: 'link',
      //   label: 'Audit Management',
      //   route: `/audit_management`,
      //   routerLinkActiveOptions: { exact: true }
      // },

      // {
      //   id: 'program',
      //   type: 'link',
      //   label: 'Programs',
      //   icon: 'mat:terminal',
      //   route: `/programs`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'sample',
      //   type: 'link',
      //   label: 'Sample',
      //   route: `/sample`,
      //   icon: 'mat:notes',
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'communication',
      //   type: 'link',
      //   label: 'Communication',
      //   route: `/communication`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'staff',
      //   type: 'link',
      //   label: 'Staff',
      //   route: `/staff`,
      //   routerLinkActiveOptions: { exact: true }
      // },

      // {
      //   id: 'roles',
      //   type: 'link',
      //   label: 'Roles',
      //   route: `/roles`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'permission',
      //   type: 'link',
      //   label: 'Permission',
      //   route: `/permission`,
      //   routerLinkActiveOptions: { exact: true }
      // },
      {
        id: 'users?isActive=Active',
        type: 'link',
        label: 'Users',
        icon: 'mat:people',
        route: `/users`
      },
      {
        id: 'master-data/program-config',
        type: 'link',
        label: 'Program Builder',
        icon: 'mat:code',
        route: `/master-data/program-config`
      },
      // {
      //   id: 'cqa-financial',
      //   type: 'link',
      //   label: 'CQA Financial',
      //   icon: 'mat:people',
      //   route: `/users`,
      //   routerLinkActiveOptions: { exact: true }
      // },

      // {
      //   id: 'quotations',
      //   type: 'link',
      //   label: 'Quotations',
      //   route: `/quotations`,
      //   icon: 'heroicons_solid:quotation-outline',
      //   routerLinkActiveOptions: { exact: true }
      // },
      // {
      //   id: 'reports',
      //   type: 'link',
      //   label: 'Reports',
      //   route: `/reports`,
      //   icon: 'mat:report',
      //   routerLinkActiveOptions: { exact: true }
      // },

      // this.path === 'admin'
      //   ?
      {
        type: 'dropdown',
        label: 'Master Data',
        icon: 'mat:settings',
        children: [
          {
            id: 'master-data/email-list',
            type: 'link',
            label: 'Email Builder',
            route: `/master-data/email-list`
          },
          {
            id: 'master-data/menus',
            type: 'link',
            label: 'Menus',
            route: `/master-data/menus`
          },
          {
            id: 'master-data/roles',
            type: 'link',
            label: 'Roles',
            route: `/master-data/roles`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'master-data/test-method',
            type: 'link',
            label: 'Test Method',
            icon: 'mat:tactic',
            route: `/master-data/test-method`
          },
          {
            id: 'master-data/uom',
            type: 'link',
            label: 'Unit Of Measure',
            icon: 'mat:scale',
            route: `/master-data/uom`
          },
          {
            id: 'master-data/test',
            type: 'link',
            label: 'Test',
            route: `/master-data/test`
          },
          {
            id: 'master-data/test-group',
            type: 'link',
            label: 'Test Group',
            route: `/master-data/test-group`
          },
          {
            id: 'master-data/laboratory-management',
            type: 'link',
            label: 'Laboratory',
            route: `/master-data/laboratory-management`
          },
          {
            id: 'master-data/domain',
            type: 'link',
            label: 'Domain Whitelist',
            route: `/master-data/domain`
          },
          {
            id: 'master-data/process-flow',
            type: 'link',
            label: 'Process Flow',
            route: `/master-data/process-flow`
          },
          {
            id: 'master-data/sample-type',
            type: 'link',
            label: 'Sample Type',
            route: `/master-data/sample-type`
          },
          {
            id: 'master-data/viscosity-grade',
            type: 'link',
            label: 'Viscosity Grade',
            route: `/master-data/viscosity-grade`
          },
          {
            id: 'master-data/specification',
            type: 'link',
            label: 'Specification',
            route: `/master-data/specification`
          },
          {
            id: 'master-data/form-type',
            type: 'link',
            label: 'Forms',
            icon: 'mat:description',
            route: `/master-data/form-type`
          },
          {
            id: 'master-data/CMS',
            type: 'link',
            label: 'CMS',
            icon: 'mat:setting',
            route: `/master-data/CMS`
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'List Configuration',
        icon: 'mat:list',
        children: [
          {
            id: 'configuration_actionItem',
            type: 'link',
            label: 'Action Item',
            route: `/list-configuration/Action Item`
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Sample',
            route: `/list-configuration/sample`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Submitted Forms',
            route: `/list-configuration/company_completed_forms_documents`,
            routerLinkActiveOptions: { exact: true }
          },

          {
            id: 'sample',
            type: 'link',
            label: 'Submitted Formulations',
            route: `/list-configuration/SubmittedFormulations`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Formulation Data',
            route: `/list-configuration/FormulationData`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Submitted Samples',
            route: `/list-configuration/SubmittedSamples`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Sample Data',
            route: `/list-configuration/SampleData`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Sample Data Reports',
            route: `/list-configuration/SampleDataReports`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Qualification Sample',
            route: `/list-configuration/QualificationSampleData`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Test Data',
            route: `/list-configuration/TestData`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Production Sample',
            route: `/list-configuration/ProductionSample`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'sample',
            type: 'link',
            label: 'Production Sample Intake Tracking',
            route: `/list-configuration/production_sample_intake_tracking`,
            routerLinkActiveOptions: { exact: true }
          },
          {
            id: 'brands',
            type: 'link',
            label: 'Brands',
            route: `/list-configuration/brands`
          },
          {
            id: 'productLabel',
            type: 'link',
            label: 'Product Labels',
            route: `/list-configuration/productLabels`
          },
          {
            id: 'payments',
            type: 'link',
            label: 'Payments',
            icon: 'mat:tactic',
            route: `/list-configuration/payments`
          },
          {
            id: 'license',
            type: 'link',
            label: 'License Agreement',
            icon: 'mat:scale',
            route: `/list-configuration/License Agreement`
          },
          {
            id: 'formulation',
            type: 'link',
            label: 'Formulation',
            route: `/list-configuration/formulations`
          },
          {
            id: 'configuration_company',
            type: 'link',
            label: 'Company',
            route: `/list-configuration/company`
          },
          {
            id: 'configuration_users',
            type: 'link',
            label: 'Users',
            route: `/list-configuration/users`
          },
          {
            id: 'configuration_forms',
            type: 'link',
            label: 'Forms',
            route: `/list-configuration/forms`
          },
          {
            id: 'configuration_quotations',
            type: 'link',
            label: 'Quotations',
            route: `/list-configuration/quotation`
          },
          {
            id: 'configuration_invoice',
            type: 'link',
            label: 'Invoice',
            route: `/list-configuration/invoice`
          }
          // {
          //   id: 'configuration_reports',
          //   type: 'link',
          //   label: 'Reports',
          //   route: `/list-configuration/reports`
          // }
        ]
      }
    ]);
  }

  CompanyAgreementRes: any;
  programResp: any;
  private readonly programsList: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  get programsList$(): Observable<any[]> {
    return this.programsList.asObservable();
  }
  async getPrograms() {
    try {
      this.programResp = await firstValueFrom(
        this.programConfigService.getProgramConfigAll()
      );
      this.programsList.next(this.programResp.items);
      this.dataService.program = this.programResp.items[0];
      this.programList = this.programResp.items.map((programData: any) => ({
        id: programData.name,
        type: 'link',
        label: programData.name,
        routerLinkActiveOptions: { exact: true },
        route: 'program/dashboard',
        key: 'program',
        data: {
          ...programData,
          company_userInfo: this.userInfo,
          programConfigId: programData?.id,
          licenseFee: programData?.licenseFee,
          applicantId: programData?.applicantId,
          isApprove: programData?.isApprove,
          fqAgreementForm: programData?.fqAgreementForm,
          licenseAgreementForm: programData?.licenseAgreementForm,
          programApplied: programData?.programApplied,
          isFinalized: programData?.isFinalized,
          isPaid: programData?.isPaid,
          programName: programData?.programName,
          bankInfo: programData?.bankInfo,
          sampleManagementList: programData?.sampleManagementList
        }
      }));
    } catch (error) {
      console.error('Error fetching :', error);
    }
  }
  async getCompanyApprovalStatus() {
    try {
      if (this.userInfo?.company?.id) {
        this.CompanyAgreementRes = await firstValueFrom(
          this.programConfigService.getProgramApprovalStatus(
            this.userInfo?.company?.id
          )
        );
      }

      await this.onGetProgramsDetails();
    } catch (err) {
      console.error('Error fetching :', err);
    }
  }

  async onGetProgramsDetails() {
    try {
      // Ensure that programResp and CompanyAgreementRes have valid data
      if (!this.programResp?.items || !this.CompanyAgreementRes?.items) {
        throw new Error(
          'Program response or company agreement response is empty'
        );
      }
      this.CompanyAgreementRes.items.forEach((i: any) => {
        this.programResp.items.forEach((j: any, index: any) => {
          if (i.programConfigId.id == j.id && i.type == 'License Agreement') {
            this.programResp.items[index].applicantId = i.id;
            this.programResp.items[index].licenseFee = i.licenseFee;
            this.programResp[index].LicenseRenewalFee = i.LicenseRenewalFee;
            this.programResp[index].Form02Status = i.Form02Status;
            if (i.id) {
              this.programResp.items[index].isAgreementRequested = true;
            } else {
              this.programResp.items[index].isAgreementRequested = false;
            }
            if (i.isApprove) {
              this.programResp.items[index].isApprove = true;
              this.programResp.items[index].fqAgreementForm = i.agreementForm;
              if (i.isFinalized) {
                this.programResp.items[index].isFinalized = true;
              }
              if (i.isPaid) {
                this.programResp.items[index].isPaid = true;
              }
            }
            if (i.isFinalized) {
              this.programResp.items[index].isFinalized = true;
            }
            if (i.isPaid) {
              this.programResp.items[index].isPaid = true;
            }
            if (i.orderId) {
              this.programResp.items[index].isOrdered = true;
              this.programResp.items[index].orderDetails = i.orderDetails;
            }
            if (i.isFormSubmitted) {
              this.programResp.items[index].isFormSubmitted = true;
            }

            if (i.isAccepted) {
              this.programResp.items[index].isAccepted = true;
            }
            if (i.isDeclined) {
              this.programResp.items[index].isDeclined = true;
              this.programResp.items[index].programApplied = false;
            }
            if (i.isRejected) {
              this.programResp[index].isRejected = true;
              this.programResp[index].programApplied = false;
            } else {
              this.programResp[index].isRejected = false;
              this.programResp[index].programApplied = false;
            }

            if (i.quotationId) {
              this.programResp[index].isQuotation = true;
              this.programResp[index].quotationDetails = i.quotationDetails;
              this.programResp[index].quotationId = i.quotationId;
            } else {
              this.programResp[index].isQuotation = false;
              this.programResp[index].quotationDetails = null;
              this.programResp[index].quotationId = null;
            }
          }
        });
      });
      this.programList = this.programResp.items.map((programData: any) => ({
        id: programData.name,
        type: 'link',
        label: programData.name,
        routerLinkActiveOptions: { exact: true },
        route: 'program/dashboard',
        key: 'program',
        data: {
          ...programData,
          company_userInfo: this.userInfo,
          programConfigId: programData?.id,
          licenseFee: programData?.licenseFee,
          applicantId: programData?.applicantId,
          isApprove: programData?.isApprove,
          fqAgreementForm: programData?.fqAgreementForm,
          licenseAgreementForm: programData?.licenseAgreementForm,
          programApplied: programData?.programApplied,
          isFinalized: programData?.isFinalized,
          isPaid: programData?.isPaid,
          programName: programData?.programName,
          bankInfo: programData?.bankInfo,
          sampleManagementList: programData?.sampleManagementList
        }
      }));
    } catch (error) {
      console.error('Error processing program details:', error);
    }
  }
  async updateProgramData() {
    try {
      await this.getPrograms();
      if (!this.userCheck.checkUser()) {
        // await this.getCompanyApprovalStatus();
      }

      this.programData = {
        type: 'dropdown',
        label: 'Program',
        icon: 'mat:info',
        children: this.programList
      };
    } catch (error) {
      console.error(
        'Error fetching program data or company approval status:',
        error
      );
    }
  }

  async get() {
    // Implement logic to filter navigation items based on role and API response
    let filteredItems: NavigationItem[] = [];
    let apiResponse: any;
    if (this.appsettingsService.userData()) {
      this.userInfo = this.appsettingsService.userData()?.userDetails;
    }
    this.role = localStorage.getItem('role');
    this.path =
      this.role == 'ROLE_ADMIN' || 'ROLE_ADMIN_STAFF' ? 'admin' : 'subadmin';

    await this.getRolePermission();
    await this.updateProgramData();

    if (this.role == 'ROLE_ADMIN') {
      // If admin, allow all items
      this.loadNavigation();

      filteredItems = this._items.value;
    } else if (this.role == 'ROLE_ADMIN_STAFF') {
      this.loadNavigation();
      // getting by menu role id
      const roleID = this.userInfo.permissions.menuRoleId;
      let menusPermissionResp = await firstValueFrom(
        this.rolesService.getByRolesId(roleID)
      );

      let items = this._items.value;
      let permission = menusPermissionResp.permissions;
      const matchedItem = items.filter((item) => {
        if (item.type === 'link') {
          return permission.some(
            (perm: any) =>
              perm?.menuID?.slug === item?.id &&
              perm?.menuAction &&
              perm?.menuAction?.id
          );
        } else if (item.type === 'dropdown') {
          item.children = item.children.filter((child: any) =>
            permission.some(
              (perm: any) =>
                perm.menuID?.slug === child?.id &&
                perm?.menuAction &&
                perm?.menuAction.id
            )
          );
          return item.children.length > 0;
        }
        return false;
      });

      filteredItems = matchedItem;
    } else if (this.role == 'ROLE_SUB_ADMIN') {
      filteredItems = [
        {
          id: 'home',
          type: 'link',
          label: 'Home',
          icon: 'mat:home',
          route: `/home`,
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: 'about',
          type: 'link',
          label: 'About',
          icon: 'mat:person',
          route: `/about`,
          routerLinkActiveOptions: { exact: true }
        },
        this.programData,
        {
          id: 'contact',
          type: 'link',
          label: 'Contact',
          icon: 'mat:call',
          route: `/contact`,
          routerLinkActiveOptions: { exact: true }
        }
      ];
    } else if (this.role == 'ROLE_SUB_ADMIN_STAFF') {
      // getting by menu role id
      const roleID = this.userInfo.permissions.menuRoleId;
      let menusPermissionResp = await firstValueFrom(
        this.rolesService.getByRolesId(roleID)
      );

      filteredItems = [
        {
          id: 'dashboard_company',
          type: 'link',
          label: 'Home',
          icon: 'mat:home',
          route: `/home`,
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: 'about',
          type: 'link',
          label: 'About',
          icon: 'mat:person',
          route: `/about`,
          routerLinkActiveOptions: { exact: true }
        },
        this.programData,
        {
          id: 'contact',
          type: 'link',
          label: 'Contact',
          icon: 'mat:call',
          route: `/contact`,
          routerLinkActiveOptions: { exact: true }
        }
      ];
    } else {
      filteredItems = [
        {
          id: 'home',
          type: 'link',
          label: 'Home',
          icon: 'mat:home',
          route: `/home`,
          routerLinkActiveOptions: { exact: true }
        },
        {
          id: 'about',
          type: 'link',
          label: 'About',
          icon: 'mat:person',
          route: `/about`,
          routerLinkActiveOptions: { exact: true }
        },
        this.programData,
        {
          id: 'contact',
          type: 'link',
          label: 'Contact',
          icon: 'mat:call',
          route: `/contact`,
          routerLinkActiveOptions: { exact: true }
        }
      ];
    }
    // Update the BehaviorSubject with the filtered items
    this._items.next(filteredItems);
  }

  isHavePermission(menuName: string, actionName: string) {
    const hasPermission = this.rolePermission.some(
      (permission: any) =>
        permission?.menuID?.slug == menuName &&
        permission?.menuAction?.name == actionName
    );

    return hasPermission;
  }
  isHavePermissionsCQA(menuName: string) {
    const hasPermission = this.rolePermission.some(
      (permission: any) => permission?.menuID?.slug == menuName
    );
    return hasPermission;
  }

  filterLinksByPermissions(links: any, slug: string) {
    const filteredLinks = links.map((link: any) => {
      if (link.label === 'Dashboard') {
        return link;
      }

      if (link.children) {
        const filteredChildren = link.children.filter((child: any) =>
          this.rolePermission.some(
            (permission) =>
              !permission.menuAction?.is_Delete &&
              child.label == permission?.menuAction?.name &&
              permission?.menuID?.slug == slug
          )
        );

        if (filteredChildren.length > 0) {
          return { ...link, children: filteredChildren };
        } else {
          return null;
        }
      }

      return this.rolePermission.some(
        (permission) =>
          !permission.menuAction?.is_Delete &&
          link.label === permission?.menuAction?.name &&
          permission?.menuID?.slug === slug
      )
        ? link
        : null;
    });

    return filteredLinks.filter((link: any) => link !== null);
  }
}
