// loader.component.ts
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from 'src/app/core/shared/shared.module';

@Component({
  selector: 'app-loader',
  template: `<div *ngIf="loading" class="loader-overlay relative">
    <div id="loader" class="text-center">
      <div class="loader mx-auto mb-4">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="text-white text-base">One moment please...</p>
    </div>
  </div>`,
  styleUrls: ['./loader.component.scss'],
  imports: [NgIf, MatProgressSpinnerModule, SharedModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() loading: boolean | null = true;
}
