<!-- Loop through each image in the images array -->
<ng-container *ngIf="images && images.length > 0">
  <div *ngFor="let image of images" class="image-container mt-4">
    <!-- Display the image with dynamic properties and error handling -->
    <img
      [src]="image.url"
      [alt]="image.alt"
      [style.width]="image.width"
      [style.height]="image.height"
      (error)="onImageError($event, image)" />

    <!-- Display full-screen icon if there is no error with the image -->
    <button
      class="full-screen-icon"
      [matTooltip]="'Fullscreen'"
      *ngIf="!image?.error"
      (click)="toggleFullScreen(image)">
      <mat-icon class="icon" svgIcon="mat:fullscreen"></mat-icon>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="imagesUrl">
  <div class="image-container">
    <!-- Display the image with dynamic properties and error handling -->
    <img
      [src]="imagesUrl"
      [alt]="altImage"
      [style.width]="style.width"
      [style.height]="style.height"
      (error)="handleImageError($event)" />

    <!-- Display full-screen icon if there is no error with the image -->
    <button
      class="full-screen-icon"
      [matTooltip]="'Fullscreen'"
      (click)="toggleFullScreenSingle(imagesUrl)">
      <mat-icon class="icon" svgIcon="mat:fullscreen"></mat-icon>
    </button>
  </div>
</ng-container>

<!-- Overlay for displaying the image in full screen -->
<div *ngIf="showImage" class="image-overlay relative">
  <!-- Close button for the full-screen overlay -->
  <button
    class="text-white font-black absolute top-4 right-4"
    mat-dialog-close
    mat-icon-button
    type="button"
    (click)="showImage = false">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>

  <!-- Container for the full-screen image with animation -->
  <div class="bg-white" @scaleFadeIn>
    <img
      [src]="image.url"
      [alt]="image.alt"
      style="width: 80vw"
      (error)="handleImageError($event)" />
  </div>
</div>

<!-- Overlay for displaying the image in full screen -->
<div *ngIf="showImageSingle" class="image-overlay relative">
  <!-- Close button for the full-screen overlay -->
  <button
    class="text-white font-black absolute top-4 right-4"
    mat-dialog-close
    mat-icon-button
    type="button"
    (click)="showImageSingle = false">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>

  <!-- Container for the full-screen image with animation -->
  <div class="bg-white" @scaleFadeIn>
    <img
      [src]="imageSingle"
      style="width: 80vw"
      (error)="handleImageError($event)" />
  </div>
</div>
