<div [ngStyle]="setup.page" class="ll-page">
    <div class="ll-print-content" [ngStyle]="{ 'grid-template-columns': cols, 'grid-template-rows': rows }">
        <ll-label-preview
            *ngFor="let item of data; let i = index"
            [qrCodeErrorCorrectionLevel]="qrCodeErrorCorrectionLevel"
            [style.border-top]="shouldPrintBorderHorizontal(i) ? setup.border : 'none'"
            [style.border-right]="setup.border"
            [style.border-bottom]="setup.border"
            [style.border-left]="shouldPrintBorderVertical(i) ? setup.border : 'none'"
            [setup]="setup"
            [data]="item"
        ></ll-label-preview>
    </div>
</div>
<ng-container *ngIf="setup.twoSided">
    <div [ngStyle]="backStyle" class="ll-page">
        <div
            class="ll-print-content"
            [ngStyle]="{ 'grid-template-columns': cols, 'grid-template-rows': rows, direction: 'rtl' }"
        >
            <ll-label-preview
                *ngFor="let item of data; let i = index"
                [qrCodeErrorCorrectionLevel]="qrCodeErrorCorrectionLevel"
                [style.border-top]="shouldPrintBorderHorizontal(i) ? setup.border : 'none'"
                [style.border-right]="shouldPrintBorderVertical(i) ? setup.border : 'none'"
                [style.border-bottom]="setup.border"
                [style.border-left]="setup.border"
                [backside]="true"
                [setup]="setup"
                [data]="item"
            ></ll-label-preview>
        </div>
    </div>
</ng-container>
