<select (change)="doValueSelect($event)" class="form-control m-3 rounded-md cursor-pointer">
    <option value="">{{ this.placeholder | translate }}...</option>
    <ng-container *ngFor="let field of availableFields | sort: 'label'">
        <ng-container *ngIf="!includePseudoFields && field.type !== fieldType.text && field.type !== fieldType.domain">
            <option [value]="field.field" *ngIf="field.type !== fieldType.qrCode" [selected]="field.field === value">
                {{ field.label }}
            </option>
        </ng-container>
    </ng-container>
</select>
