import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IFontStyle } from '../../../label-designer.interface';

/**
 * @internal
 */
@Component({
  selector: 'll-font-settings',
  templateUrl: './font-settings.component.html',
  styleUrls: ['./font-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontSettingsComponent implements OnInit {
  fontFamily: string[] = [
    'Cormorant Garamond',
    'Merriweather',
    'Noto serif',
    'Open Sans',
    'Open sans Condensed',
    'Old Standard TT',
    'Source Code pro'
  ];
  textTransform: string[] = ['capitalize', 'uppercase', 'lowercase'];
  fontSizes: number[] = [];
  lineHeight: number[] = [
    0.9, 1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.75, 2
  ];

  @Input() allowEmptyFontFamily = false;
  @Input() showUnderline = false;
  @Input() minimal = false;
  // @Input() fontSettings: IFontStyle;
  @Output() fontSettingsChange = new EventEmitter<IFontStyle>();
  selectedTextAlign: any;
  selectedTextDecoration: any;
  selectedTextWeight: any;
  selectedFontStyle: any;
  @Input() fontSettings: IFontStyle | any;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const sizes = [];
    for (let i = 4; i <= 20; i++) {
      sizes.push(i);
    }
    this.fontSizes = sizes;
  }

  @Input() set _fontSettings(item: IFontStyle) {
    this.fontSettings = item;
    this.selectedTextAlign = this.fontSettings['text-align'];
    this.selectedTextWeight = this.fontSettings['font-weight'];
    this.selectedTextDecoration = this.fontSettings['text-decoration'];
    this.selectedFontStyle = this.fontSettings['font-style'];
  }

  change(field: string | any, event: any, forceNumeric = false) {
    console.log('event :>> ', event);
    const value = event?.target?.value ?? event;
    console.log(value);
    if (!value) {
      const result: any = {};

      const currentSetting: any = this.fontSettings || {};
      Object.keys(currentSetting).forEach((key: any) => {
        result[key] = key === field ? undefined : currentSetting[key];
      });
      return this.fontSettingsChange.emit(result);
    }
    this.fontSettingsChange.emit({
      ...this.fontSettings,
      [field]: forceNumeric ? Number(value) : value
    });
    this._cdr.detectChanges();
  }
}
