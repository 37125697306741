import { NgIf, AsyncPipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VexProgressBarComponent } from '@vex/components/vex-progress-bar/vex-progress-bar.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoaderService } from 'src/app/service/loader/loader.service';

@Component({
  selector: 'empty-layout',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    VexProgressBarComponent,
    LoaderComponent,
    AsyncPipe
  ]
})
export class EmptyLayoutComponent implements OnDestroy {
  isLoading$ = this.loaderService.isLoading$;
  /**
   * Constructor
   */
  constructor(private loaderService: LoaderService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {}
}
