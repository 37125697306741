import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, catchError, map, of } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class HazardSymbolService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getHazardSymbolList(queryParams: any): Observable<any> {
    let params = this.appSettingsService.queryStringFormat(queryParams);

    return this.http.get('hazard-labels?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            const label = item?.label
              ? `${environment.mediaUrl}${item?.label}`
              : null;
            let temp: any = {
              ...item,
              createdAt: item.createdAt
                ? moment(item.createdAt).format('YYYY MMMM DD')
                : '-',
              updatedAt: item.updatedAt
                ? moment(item.updatedAt).format('YYYY MMMM DD')
                : '-',
              label: label,
              isActive: item.isActive
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      }),
      catchError((error) => {
        // Handle the error here and return a fallback or default response
        let fallbackResponse = {
          items: [],
          totalCount: 0,
          error: 'An error occurred while fetching the data'
        };

        // Optionally, set the fallback error message
        if (error?.message) {
          fallbackResponse.error = error.message;
        }

        return of(fallbackResponse); // Return the fallback observable
      })
    );
  }

  createHazardSymbol(data: any): Observable<any> {
    return this.http.post('hazard-labels', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getByHazardSymbolId(id: any): Observable<any> {
    return this.http.get('hazard-labels/' + id).pipe(
      map((resp: any) => {
        let HazardSymbol: any;
        if (resp && resp.data) {
          let data = resp.data;
          HazardSymbol = data;
        }
        return HazardSymbol;
      })
    );
  }

  updateHazardSymbol(id: any, data: any): Observable<any> {
    return this.http.put('hazard-labels/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteHazardSymbol(id: any): Observable<any> {
    return this.http.delete('hazard-labels/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  getAllHazardSymbol(param: any): Observable<any> {
    const params =
      this.appSettingsService.queryStringFormatWithoutOffset(param);
    return this.http.get('hazard-labels?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            const label = item?.label
              ? `${environment.mediaUrl}${item?.label}`
              : null;
            let temp: any = {
              ...item,
              createdAt: item.createdAt
                ? moment(item.createdAt).format('YYYY MMMM DD')
                : '-',
              updatedAt: item.updatedAt
                ? moment(item.updatedAt).format('YYYY MMMM DD')
                : '-',
              label: label,
              isActive: item.isActive
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      }),
      catchError((error) => {
        // Handle the error here and return a fallback or default response
        let fallbackResponse = {
          items: [],
          totalCount: 0,
          error: 'An error occurred while fetching the data'
        };

        // Optionally, set the fallback error message
        if (error?.message) {
          fallbackResponse.error = error.message;
        }

        return of(fallbackResponse); // Return the fallback observable
      })
    );
  }
}
