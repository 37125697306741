<div
    [style.position]="'absolute'"
    [ngStyle]="_item.style"
    [style.top.mm]="_item.y"
    [style.left.mm]="_item.x"
    style="white-space: nowrap"
    [ngClass]="{
        'overflow-hidden': this._item.fields[0].type != 'barcode',
        'overflow-visible': this._item.fields[0].type == 'barcode'
    }"
>
    <ng-container *ngFor="let field of _item.fields; let l = last">
        <ll-label-item-field
            [height]="height"
            [width]="width"
            [size]="size"
            [field]="field"
            [isLast]="l"
            [qrCodeErrorCorrectionLevel]="qrCodeErrorCorrectionLevel"
        ></ll-label-item-field>
    </ng-container>
</div>
