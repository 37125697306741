<div *ngIf="loading" class="loader-overlay relative">
  <div id="loader" class="text-center">
    <div class="loader mx-auto mb-4">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p class="text-white text-base">One moment please...</p>
  </div>
</div>
