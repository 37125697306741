<div
  #item
  class="editor-item"
  [class.active]="active"
  cdkDrag
  [cdkDragBoundary]="boundary"
  (cdkDragStarted)="recordElementDimensions()"
  (cdkDragEnded)="onMoveEnd()"
  [style.width.mm]="width"
  [style.height.mm]="height"
  [style.top.mm]="y"
  [style.left.mm]="x"
  [ngStyle]="{ transform: 'rotate(' + rotateValue + 'deg)' }">
  <div class="editor-item-wrap">
    <div class="drag-handle cursor-pointer">
      <ll-icon
        type="move"
        class="cursor-pointer"
        cdkDragHandle
        [size]="18"></ll-icon>
    </div>
    <div
      class="resize-handle cursor-pointer"
      cdkDrag
      (cdkDragStarted)="recordElementDimensions()"
      (cdkDragEnded)="onResizeEnd($event)"
      (cdkDragMoved)="onResize($event)"
      *ngIf="
        _item.fields[0]?.type != 'barcode' && _item.fields[0]?.type != 'qr-code'
      ">
      <ll-icon type="resize" class="cursor-pointer" [size]="18"></ll-icon>
    </div>
    <div class="option-handle cursor-pointer">
      <ll-icon
        [size]="18"
        type="edit"
        (click)="showSettings.emit(_item)"></ll-icon>
    </div>

    <!-- <div
            class="rotate-handle cursor-pointer"
            *ngIf="_item.fields[0]?.field_type == 'dynamic'"
        >
            <mat-icon
                [size]="20"
                class="text-black cursor-pointer"
                (click)="setRotate()"
                >autorenew</mat-icon
            >
        </div> -->

    <div class="delete-handle cursor-pointer">
      <mat-icon
        svgIcon="mat_outline:delete"
        class="text-black cursor-pointer"
        (click)="onDeleteField()"></mat-icon>
    </div>

    <div class="done-handle cursor-pointer">
      <mat-icon
        class="cursor-pointer"
        svgIcon="mat_outline:check"
        (click)="onDoneClick()"></mat-icon>
    </div>

    <div
      class="editor-item-content ellipsis flex items-center"
      (click)="onItemClicked()"
      [ngStyle]="getStyles(_item)">
      <ng-container *ngFor="let field of _item.fields; let l = last">
        <ng-container *ngIf="field.type === fieldType.qrCode; else plainText">
          <!-- <qrcode
            class="qr_code w-full h-full"
            [qrdata]="field.content"
            [level]="'M'"
            [size]="size"
            [usesvg]="true"></qrcode> -->
        </ng-container>

        <ng-template #plainText>
          <ng-container
            *ngIf="
              (field?.field_type == 'dynamic' ||
                field?.field_type == 'title') &&
              field?.text_type != 'message' &&
              field?.type != 'img'
            ">
            <input
              (click)="onItemClicked()"
              type="text"
              #inputField
              class="text-black ellipsis w-[100%] h-[100%]"
              [ngStyle]="getStyles(_item)"
              [value]="field.content"
              (input)="textFieldChanged(field, $event)" />
          </ng-container>
          <ng-container *ngIf="field?.text_type == 'message'">
            <textarea
              (click)="onItemClicked()"
              type="text"
              #inputField
              class="text-black ellipsis w-[100%] h-[100%]"
              [ngStyle]="getStyles(_item)"
              style="text-wrap: auto; line-height: normal"
              [value]="field.content"
              (input)="textFieldChanged(field, $event)"></textarea>
          </ng-container>
          <div
            (click)="onItemClicked()"
            *ngIf="field?.field_type == 'static' && field?.type != 'img'"
            class="editor-item-content flex items-center text-[#4f46E5] ellipsis"
            [ngStyle]="getStyles(_item)"
            #inputField>
            {{ field?.type == "img" ? field.label : field.content }}
          </div>
          <div
            *ngIf="field?.type == 'img'"
            class="editor-item-content text-[#4f46E5] ellipsis">
            <img
              (click)="onItemClicked()"
              [ngStyle]="getStyles(_item)"
              *ngIf="field.type == 'img'"
              [src]="field.content"
              alt="image" />
          </div>

          <span *ngIf="!l" [innerHTML]="field.separator | separator"></span>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
