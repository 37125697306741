import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ILabelField,
  ILabelItem,
  ISetup,
  FieldType,
  TLabelLocation
} from '../../label-designer.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '../../translate/translate.service';
import { Presets } from '../../presets';
import { LabelMakerFacade } from '../label-maker.facade';
import { IViewSettings } from '../../label-designer.interface';

/**
 * @internal
 */
@Component({
  selector: 'll-label-settings',
  templateUrl: './label-settings.component.html',
  styleUrls: ['./label-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelSettingsComponent implements OnInit {
  @Input() setup: ISetup | any;
  @Input() availableFields: ILabelField[];
  @Output() setupChange = new EventEmitter<ISetup>();
  @Output() closeSidebar = new EventEmitter<void>();
  showFieldFont = false;
  canDelete = false;
  borders = Presets.Border;
  selectedLabelItemFieldName: string;

  private _selectedLabelItem: ILabelItem | any;

  constructor(
    private translateService: TranslateService,
    private labelMakerFacade: LabelMakerFacade,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  //For Grid

  @Input() viewSettings: IViewSettings = {
    magnification: 2,
    previewMagnification: 2,
    grid: 1,
    gridVisible: true,
    fullscreen: false
  };
  @Output() viewSettingsChange = new EventEmitter<IViewSettings>();

  magnifications: number[] = [1, 2, 3, 4, 5];
  gridOptions: number[] = [0.5, 1, 1.5, 2];

  changeGrid<K extends keyof IViewSettings>(
    field: K,
    value: IViewSettings[K]
  ): void {
    console.log('value', value);
    this.viewSettingsChange.emit({
      ...this.viewSettings,
      [field]: value
    });
  }

  @Input() set selectedLabelItem(item: ILabelItem) {
    this._selectedLabelItem = item;
    this.selectedLabelItemFieldName =
      this._selectedLabelItem?.fields[0]?.field_type == 'static'
        ? this._selectedLabelItem?.fields[0]?.title
        : this._selectedLabelItem?.fields[0]?.content;
    if (item && item.fields) {
      this.canDelete = item.fields.length > 1;
      this.showFieldFont =
        item.fields[0] && item.fields[0].type !== FieldType.qrCode;
    } else {
      this.canDelete = false;
      this.showFieldFont = false;
    }
  }

  get selectedLabelItem() {
    return this._selectedLabelItem;
  }

  change(field: string, value: any) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      this.setupChange.emit({
        ...this.setup,
        [field]: {
          ...this.setup[field],
          ...value
        }
      });
    } else {
      this.setupChange.emit({
        ...this.setup,
        [field]: value
      });
    }
    this.labelMakerFacade.hasChanges(true);
    this._cdr.detectChanges();
  }

  changeSelectedItem(field: string, item: any) {
    const { itemIdx, location } = this.findItem();

    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].slice(0, itemIdx),
        {
          ...this.setup[location][itemIdx],
          [field]: item
        },
        ...this.setup[location].slice(itemIdx + 1)
      ]
    });
  }
  remove() {
    this.closeSidebar.emit();
  }
  fieldRemove(idx: number) {
    const { itemIdx, location } = this.findItem();
    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].slice(0, itemIdx),
        {
          ...this.setup[location][itemIdx],
          fields: [
            ...this.setup[location][itemIdx].fields.slice(0, idx),
            ...this.setup[location][itemIdx].fields.slice(idx + 1)
          ]
        },
        ...this.setup[location].slice(itemIdx + 1)
      ]
    });
    this.labelMakerFacade.hasChanges(true);
  }

  fieldAdd(labelField: ILabelField) {
    if (!labelField) {
      return;
    }
    const { itemIdx, location } = this.findItem();
    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].slice(0, itemIdx),
        {
          ...this.setup[location][itemIdx],
          fields: [...this.setup[location][itemIdx].fields, labelField]
        },
        ...this.setup[location].slice(itemIdx + 1)
      ]
    });
    this.labelMakerFacade.hasChanges(true);
  }

  fieldUpdate(labelField: ILabelField, idx: number) {
    const { itemIdx, location } = this.findItem();
    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].slice(0, itemIdx),
        {
          ...this.setup[location][itemIdx],
          fields: [
            ...this.setup[location][itemIdx].fields.slice(0, idx),
            labelField,
            ...this.setup[location][itemIdx].fields.slice(idx + 1)
          ]
        },
        ...this.setup[location].slice(itemIdx + 1)
      ]
    });

    this.labelMakerFacade.hasChanges(true);
  }

  drop(event: CdkDragDrop<ILabelField[]>) {
    const { itemIdx, location } = this.findItem();
    if (itemIdx === -1) {
      return;
    }
    const list = [...this._selectedLabelItem.fields];
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].slice(0, itemIdx),
        {
          ...this.setup[location][itemIdx],
          fields: list
        },
        ...this.setup[location].slice(itemIdx + 1)
      ]
    });
    this.labelMakerFacade.hasChanges(true);
  }

  round(value: any) {
    return Math.round(value * 1000) / 1000;
  }

  changePosition(pos: string, event: Event) {
    const element = event.target as HTMLInputElement;
    const value = Number(element.value);
    if (!value) {
      return;
    }
    const dim = pos === 'x' ? 'width.mm' : 'height.mm';
    if (value + this._selectedLabelItem.style[dim] > this.setup.label[dim]) {
      element.value = this._selectedLabelItem[pos];
      return alert(this.translateService.get('Field cannot fit the label!'));
    }
    this.changeSelectedItem(pos, value);
  }

  changeActiveStyle(style: string, event: Event) {
    const element = event.target as HTMLInputElement;
    const value = Number(element.value);
    if (!value) {
      return;
    }
    const pos = style === 'width.mm' ? 'x' : 'y';
    if (value + this._selectedLabelItem[pos] > this.setup.label[style]) {
      element.value = this._selectedLabelItem.style[style];
      return alert(this.translateService.get('Field cannot fit the label!'));
    }

    this.changeSelectedItem('style', {
      ...this._selectedLabelItem.style,
      [style]: value
    });
  }

  private findItem(item?: ILabelItem): {
    location: TLabelLocation;
    itemIdx: number;
  } {
    const labelItem = item || this._selectedLabelItem;

    let location: TLabelLocation = 'labelItems';
    let itemIdx = this.setup.labelItems.findIndex(
      (i: any) => i.id === labelItem.id
    );
    // if (itemIdx === -1) {
    //   location = 'backSideLabelItems';
    //   itemIdx = (this.setup.backSideLabelItems || []).findIndex(
    //     (i: any) => i === labelItem
    //   );
    // }
    return { location, itemIdx };
  }

  changeImageUrl(field: string, event: any) {
    const element = event.target as HTMLInputElement;
    const value = element.value;
    const { itemIdx, location } = this.findItem();
    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this.setup,
      [location]: [
        ...this.setup[location].map((item: any, index: number) => {
          return index === itemIdx
            ? {
                ...item,
                fields: item.fields.map((fieldItem: any, fieldIdx: number) =>
                  fieldIdx === 0
                    ? {
                        ...fieldItem,
                        [field]: value
                      }
                    : fieldItem
                )
              }
            : item;
        })
      ]
    });

    this.labelMakerFacade.hasChanges(true);
    this._cdr.detectChanges();
  }
}
