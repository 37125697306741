<table>
  <tr *ngIf="!backSide">
    <td></td>
    <td class="width-settings">
      Width
      <mat-form-field class="h-[40px] ml-2 w-20" [subscriptSizing]="'dynamic'">
        <input
          matInput
          type="text"
          class="mb-2 text-center"
          type="text"
          [value]="convertMmToInches(_setup.label['width.mm'])"
          (change)="updateDimensions($event, 'width.mm', 'label')"
          placeholder="inch." />
      </mat-form-field>
      inch
    </td>
  </tr>
  <tr>
    <td *ngIf="!backSide" class="height-settings flex">
      <span class="mt-2">Height</span>
      <mat-form-field class="h-15 ml-2 w-20">
        <input
          matInput
          type="text"
          class="mb-2 text-center"
          [value]="convertMmToInches(_setup.label['height.mm'])"
          (change)="updateDimensions($event, 'height.mm', 'label')"
          placeholder="inch." />
      </mat-form-field>
      <span class="mt-2 ml-1">inch</span>
    </td>
    <td>
      <div class="editor-area shadow-md rounded bg-white">
        <div
          class="editor bg-[#f1f5f9]"
          id="{{ backSide ? 'back-side-label-editor' : 'label-editor' }}"
          [ngStyle]="_magnifiedStyle"
          style="font-style: none !important"
          *ngIf="init">
          <ll-editor-grid
            *ngIf="gridVisible"
            [grid]="grid"
            [magnification]="_magnification"></ll-editor-grid>
          <ng-container *ngIf="backSide; else frontSide">
            <ll-editor-item
              *ngFor="let item of _setup.backSideLabelItems; let i = index"
              [grid]="grid"
              [magnification]="_magnification"
              [item]="item"
              [setup]="_setup"
              [selectedLabelItem]="_selectedLabelItem"
              [boundary]="'#back-side-label-editor'"
              (done)="done.emit()"
              (itemClick)="setActiveItem($event)"
              (itemChange)="onItemChange(item, $event)"
              (fieldDelete)="fieldDelete($event)"
              (fieldChange)="fieldUpdate($event)"
              [active]="item === active"
              [maxWidth]="_setup.label['width.mm']"
              [maxHeight]="_setup.label['height.mm']"
              (showSettings)="showSettings.emit($event)"></ll-editor-item>
          </ng-container>
          <ng-template #frontSide>
            <ll-editor-item
              *ngFor="let item of _setup.labelItems; let i = index"
              [grid]="grid"
              [magnification]="_magnification"
              [item]="item"
              [setup]="_setup"
              [selectedLabelItem]="_selectedLabelItem"
              [boundary]="'#label-editor'"
              (done)="done.emit()"
              (itemClick)="setActiveItem($event)"
              (itemChange)="onItemChange(item, $event)"
              [field]="field"
              (fieldChange)="fieldUpdate($event)"
              (fieldDelete)="fieldDelete($event)"
              [active]="item === active"
              [maxWidth]="_setup.label['width.mm']"
              [maxHeight]="_setup.label['height.mm']"
              (showSettings)="showSettings.emit($event)"></ll-editor-item>
          </ng-template>
        </div>
      </div>
    </td>
  </tr>
</table>
<ll-ruler *ngIf="!init" (measured)="init = true"></ll-ruler>
