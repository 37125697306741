import { Component, Input } from '@angular/core';

@Component({
  selector: 'vex-label-sidenav-items',
  templateUrl: './label-sidenav-items.component.html',
  styleUrls: ['./label-sidenav-items.component.scss']
})
export class LabelSidenavItemsComponent {
  @Input() item: any;
}
