<div class="flex flex-col min-w-0 overflow-hidden">
  <mat-drawer-container class="flex-auto h-full">
    <!-- Drawer -->

    <mat-drawer
      class="w-56 dark:bg-gray-900"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer>
      <!--sidebar -->
      <vex-scrollbar class="flex-1 flex flex-col">
        <ng-container *ngIf="getLabelLoader(); else labelMenuItems">
          <div class="flex justify-center items-center h-screen">
            <!-- Show loading spinner -->
            <div id="loader" class="text-center">
              <div class="loader mx-auto mb-4">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="text-black text-base">One moment please...</p>
            </div>
          </div>
        </ng-container>

        <ng-template #labelMenuItems>
          <div *ngFor="let label of menuData">
            <vex-label-sidenav-items [item]="label"></vex-label-sidenav-items>
          </div>
        </ng-template>
      </vex-scrollbar>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content>
      <div class="flex flex-row min-w-0 w-full overflow-y-auto">
        <ng-container *ngIf="showSettingPage">
          <div
            class="flex flex-col w-full min-w-0 lg:min-w-90 lg:max-w-90 border-r z-10">
            <ll-label-settings
              [viewSettings]="viewSettings"
              (viewSettingsChange)="onViewSettingsChange($event)"
              [setup]="_setup"
              (setupChange)="setupChanged($event)"
              (closeSidebar)="closeSidebar()"
              [selectedLabelItem]="_selectedLabelItem"
              [availableFields]="availableFields"></ll-label-settings>
          </div>
        </ng-container>

        <div
          class="flex flex-col items-center justify-center h-screen w-full p-5 relative">
          <div class="editor-wrap absolute top-10 left-10">
            <div class="editor-space">
              <ll-label-editor
                [active]="_selectedLabelItem"
                [selectedLabelItem]="_selectedLabelItem"
                (activeChange)="setActiveLabelItem($event)"
                (done)="done()"
                [setup]="_setup"
                (setupChange)="onSetupChanged($event)"
                (filedDelete)="deleteLabel($event)"
                [grid]="_viewSettings.grid"
                [gridVisible]="_viewSettings.gridVisible"
                [magnification]="_viewSettings.magnification"
                (showSettings)="showSettings($event)"></ll-label-editor>
              <div
                *ngIf="_setup && _setup?.twoSided"
                style="margin-top: 10px; display: inline-block">
                <ll-label-editor
                  [backSide]="true"
                  [active]="_selectedLabelItem"
                  [selectedLabelItem]="_selectedLabelItem"
                  (activeChange)="setActiveLabelItem($event)"
                  (done)="done()"
                  [setup]="_setup"
                  (setupChange)="onSetupChanged($event)"
                  (filedDelete)="deleteLabel($event)"
                  [grid]="_viewSettings.grid"
                  [gridVisible]="_viewSettings.gridVisible"
                  [magnification]="_viewSettings.magnification"
                  (showSettings)="showSettings($event)"></ll-label-editor>
              </div>
            </div>
            <div class="preview-space ml-[190px]">
              <strong>{{ "Preview" | translate }}</strong>
              <!-- <ll-label-preview-pager
                [active]="previewActive"
                (activeChange)="setPreviewActive($event)"
                [data]="data"></ll-label-preview-pager> -->
              <!-- <small *ngIf="dimensions">
                {{
                  "Labels on page"
                    | translate
                      : {
                          total: dimensions.cols * dimensions.rows,
                          cols: dimensions.cols,
                          rows: dimensions.rows
                        }
                }}
              </small> -->
              <br />
              <div
                class="preview-elements"
                [ngStyle]="{
                  transform:
                    'scale(' + (_viewSettings.previewMagnification || 1) + ')'
                }">
                <div
                  class="bg-white m-0 p-2 border border-[#ccc] border-solid inline-block rounded">
                  <ll-label-preview
                    [qrCodeErrorCorrectionLevel]="qrCodeErrorCorrectionLevel"
                    [setup]="_setup"
                    [data]="_data[previewActive]"></ll-label-preview>
                </div>
                <div
                  *ngIf="_setup && _setup?.twoSided"
                  style="
                    margin-top: 5px;
                    border: 1px solid #999;
                    display: inline-block;
                  ">
                  <ll-label-preview
                    [qrCodeErrorCorrectionLevel]="qrCodeErrorCorrectionLevel"
                    [setup]="_setup"
                    [data]="_data[previewActive]"
                    [backside]="true"></ll-label-preview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <div>
    <mat-divider></mat-divider>
    <!-- commented the actions -->
    <!-- <div class="flex justify-between bg-white items-center px-7 py-4">
      <div class="flex items-center gap-2">
        <button mat-stroked-button class="cursor-pointer gap-2">
          <mat-icon svgIcon="heroicons_outline:cog"></mat-icon>
          Settings
        </button>
        <button
          mat-stroked-button
          class="cursor-pointer gap-2"
          (click)="onSetPaperSize()">
          <mat-icon svgIcon="mat_outline:phonelink_setup"></mat-icon>
          Set Label Size
        </button>
        <button
          mat-stroked-button
          class="cursor-pointer gap-2 text-[#ff0000]"
          (click)="onPreviewPrint()">
          <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
          Print Preview
        </button>
        <button
          mat-stroked-button
          class="cursor-pointer gap-2"
          (click)="onTestPrint()">
          Developer
        </button>
      </div>
      <div class="flex items-center gap-2">
        <button
          mat-stroked-button
          type="button"
          class="cursor-pointer w-full gap-2 cancel">
          <mat-icon
            class="min-h-2"
            svgIcon="heroicons_outline:arrow-uturn-left"></mat-icon>
          Undo
        </button>
        <button
          mat-stroked-button
          type="button"
          class="cursor-pointer w-full gap-2 cancel">
          <mat-icon
            class="min-h-2"
            svgIcon="heroicons_outline:arrow-uturn-right"></mat-icon>
          Redo
        </button>
        <button
          mat-flat-button
          class="cursor-pointer w-full gap-2 text-white bg-[#4f46e5]">
          <mat-icon svgIcon="mat_outline:save"></mat-icon>
          Save
        </button>
      </div>
    </div> -->
  </div>
</div>
