import { Component, Inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'vex-common-date-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    MatDatepickerModule
  ],
  template: ` <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ title }}
      </h2>

      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content>
      <mat-form-field class="m-2 w-full">
        <mat-label>{{ DateLabel }}</mat-label>
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="datePicker"
          [formControl]="dateControl" />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error
          *ngIf="
            dateControl?.invalid && (dateControl?.dirty || dateControl?.touched)
          ">
          Date is required
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button
        color="primary"
        mat-flat-button
        type="submit"
        (click)="onSubmit()">
        Submit
      </button>
    </mat-dialog-actions>`,
  styles: ['']
})
export class CommonDateDialogComponent {
  title: string = 'Date';
  dateControl = new FormControl('', Validators.required);
  maxDate: Date = new Date();
  DateLabel: string = 'Select Date';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { title: string; DateLabel: string },
    private dialogRef: MatDialogRef<CommonDateDialogComponent>
  ) {
    this.title = data?.title;
    this.DateLabel = data?.DateLabel;
  }

  onSubmit() {
    if (this.dateControl.invalid) {
      this.dateControl.markAllAsTouched();
      return;
    }
    this.dialogRef.close({ value: this.dateControl.value });
  }
}
