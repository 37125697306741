<div
  class="min-w-0 lg:min-w-90 lg:max-w-90 flex flex-col overflow-auto h-screen">
  <div class="flex flex-col gap-3 py-8 overflow-y-auto bg-card h-full">
    <div class="flex flex-row items-center justify-between px-3">
      <div class="text-blue-600 heading w-[90%]">
        {{ "label" | translate }}
      </div>
      <div (click)="remove()" class="pull-right cursor-pointer">
        <ll-icon [size]="20" type="close"></ll-icon>
      </div>
    </div>
    <ll-font-settings
      [_fontSettings]="setup.label"
      (fontSettingsChange)="change('label', $event)"></ll-font-settings>
    <ng-container *ngIf="selectedLabelItem.type == 'field'">
      <div class="text-blue-600 heading px-3">
        {{ selectedLabelItemFieldName + " " + "Field" | translate }}
      </div>

      <div class="bg-[#f1f5f9] m-1 rounded-lg">
        <ll-font-settings
          *ngIf="showFieldFont"
          [allowEmptyFontFamily]="true"
          [_fontSettings]="selectedLabelItem.style"
          (fontSettingsChange)="changeSelectedItem('style', $event)"
          [showUnderline]="true"></ll-font-settings>

        <div class="flex p-3 gap-3">
          <ng-container
            *ngTemplateOutlet="
              inputTemplate;
              context: { selectedItem: selectedLabelItem }
            "></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedLabelItem.type == 'img'">
      <div class="text-blue-600 heading px-3">
        {{ "image" | translate }}
      </div>

      <div class="bg-[#f1f5f9] rounded-lg">
        <div class="flex flex-col p-3 gap-3">
          <div class="flex flex-col text-left text-[#909090]">
            {{ "Image URL" | translate }}
            <div class="relative">
              <input
                class="form-control w-64 bg-white"
                type="text"
                [value]="selectedLabelItem.fields[0].content"
                (change)="changeImageUrl('content', $event)"
                placeholder="https://example.com/image" />
            </div>
          </div>
          <div class="flex flex-row p-3 gap-3">
            <ng-container
              *ngTemplateOutlet="
                inputTemplate;
                context: { selectedItem: selectedLabelItem }
              "></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template *ngIf="!selectedLabelItem">
      <div class="w-full mr-2 px-3">
        <div class="text-blue-600 heading">
          {{ "Field" | translate }}
        </div>
      </div>
      <p class="h-13 px-3 flex justify-center items-center">
        {{ "No active field selected" | translate }}
      </p>
    </ng-template>

    <ll-margin-settings
      [style]="setup.label"
      (styleChange)="change('label', $event)"></ll-margin-settings>

    <div class="bg-[#e2e5e7]">
      <!-- Show Grid Toggle -->
      <div
        class="flex flex-row items-center hover:bg-gray-100 rounded-md cursor-pointer px-4 py-2"
        (click)="changeGrid('gridVisible', !viewSettings.gridVisible)">
        <div
          class="flex items-center justify-center w-6 h-6 border border-gray-300 rounded bg-white mr-3">
          <ll-icon
            [type]="'check'"
            [size]="18"
            *ngIf="viewSettings.gridVisible"
            class="text-blue-600"></ll-icon>
        </div>
        <div class="text-blue-600 heading">{{ "Show grid" | translate }}</div>
      </div>
      <!-- Grid Size Options -->
      <ng-container *ngIf="viewSettings.gridVisible">
        <div
          class="flex flex-row items-center hover:bg-gray-100 rounded-md cursor-pointer px-4 py-2"
          *ngFor="let g of gridOptions"
          (click)="changeGrid('grid', viewSettings.grid === g ? undefined : g)">
          <div
            class="flex items-center justify-center border border-gray-500 rounded bg-white mr-3 w-4 h-4">
            <ll-icon
              [type]="'check'"
              [size]="14"
              *ngIf="viewSettings.grid === g"
              class="text-blue-600"></ll-icon>
          </div>
          <div class="flex flex-row items-center">
            <span class="text-gray-700 font-medium">{{ g }}</span>
            <span class="text-gray-500">mm</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- The alignment content template -->
<ng-template #inputTemplate let-selectedItem="selectedItem">
  <div class="flex flex-col text-left text-[#909090]">
    {{ "Left" | translate }}
    <div class="relative">
      <input
        class="form-control w-18 bg-white"
        type="text"
        maxlength="1000"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
        [value]="round(selectedItem.x)"
        (change)="changePosition('x', $event)" />
      <span class="absolute top-1 right-0.5 text-sm">mm.</span>
    </div>
  </div>
  <div class="flex flex-col text-left text-[#909090]">
    {{ "Top" | translate }}
    <div class="relative">
      <input
        class="form-control w-18 bg-white"
        type="text"
        maxlength="1000"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
        [value]="round(selectedItem.y)"
        (change)="changePosition('y', $event)" />
      <span class="absolute top-1 right-0.5 text-sm">mm.</span>
    </div>
  </div>
  <div class="flex flex-col text-left text-[#909090]">
    {{ "Width" | translate }}
    <div class="relative">
      <input
        class="form-control w-18 bg-white"
        type="text"
        maxlength="1000"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
        [value]="round(selectedItem.style['width.mm'])"
        (change)="changeActiveStyle('width.mm', $event)" />
      <span class="absolute top-1 right-0.5 text-sm">mm.</span>
    </div>
  </div>
  <div class="flex flex-col text-left text-[#909090]">
    {{ "Height" | translate }}
    <div class="relative">
      <input
        class="form-control w-18 bg-white"
        type="text"
        maxlength="1000"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
        [value]="round(selectedItem.style['height.mm'])"
        (change)="changeActiveStyle('height.mm', $event)" />
      <span class="absolute top-1 right-0.5 text-sm">mm.</span>
    </div>
  </div>
</ng-template>
