import { ChangeDetectorRef, Component } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { VexConfigService } from '@vex/config/vex-config.service';

import { AsyncPipe, NgIf } from '@angular/common';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { cartComponent } from '../components/cart/cart.component';

import { MatDialogModule } from '@angular/material/dialog';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { VexProgressBarComponent } from '@vex/components/vex-progress-bar/vex-progress-bar.component';
import {
  VexColorScheme,
  VexConfig,
  VexConfigName
} from '@vex/config/vex-config.interface';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { NavigationLoaderService } from 'src/app/core/navigation/navigation-loader.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authentication.service';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoaderService } from 'src/app/service/loader/loader.service';

@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    BaseLayoutComponent,
    NgIf,
    AsyncPipe,
    SidenavComponent,
    ToolbarComponent,
    FooterComponent,
    cartComponent,
    MatDialogModule,
    MatSidenavModule,
    RouterOutlet,
    VexProgressBarComponent,
    LoaderComponent
  ],
  standalone: true
})
export class LayoutComponent {
  config$: Observable<VexConfig> = this.configService.config$;
  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavDisableClose$: Observable<boolean> = this.layoutService.isDesktop$;
  sidenavFixedInViewport$: Observable<boolean> =
    this.layoutService.isDesktop$.pipe(map((isDesktop) => !isDesktop));
  sidenavMode$: Observable<MatDrawerMode> = combineLatest([
    this.layoutService.isDesktop$,
    this.configService.select((config) => config.layout)
  ]).pipe(
    map(([isDesktop, layout]) =>
      !isDesktop || layout === 'vertical' ? 'over' : 'side'
    )
  );
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;
  configPanelOpen$: Observable<boolean> = this.layoutService.configPanelOpen$;
  cartOpen$: Observable<boolean> = this.layoutService.cartOpen$;
  userRole: any;
  userInfo: any;
  isLoading$ = this.loaderService.isLoading$;
  showFooter: boolean = true;
  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private appSettingsService: AppSettingsService,
    private navigationLoaderService: NavigationLoaderService,
    private userValidate: UserAuthenticationService,
    private router: Router,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userRole = this.appSettingsService.getUserRole();
    this.userInfo = this.appSettingsService.userData();
    //calling programs,roles,menus
    // this.navigationLoaderService.get();
    if (this.userValidate.checkUser()) {
      this.appSettingsService.setConfig(
        VexConfigName.apollo,
        VexColorScheme.LIGHT
      );
    } else if (!this.userValidate.checkUser()) {
      this.appSettingsService.setConfig(
        VexConfigName.ikaros,
        VexColorScheme.LIGHT
      );
    } else {
      this.appSettingsService.setConfig(
        VexConfigName.ikaros,
        VexColorScheme.LIGHT
      );
    }
    this.onShowfooter();
  }

  onSidenavClosed(): void {
    this.layoutService.closeSidenav();
  }

  onCartClosed(): void {
    this.layoutService.closeCart();
  }

  onShowCart() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event);
        return false;
      }
    });
    return false;
  }
  onShowfooter() {
    if (this.userValidate.checkUser()) {
      const checkUrl = (url: string) => {
        let routesListHideFooter = ['program/add'];
        if (routesListHideFooter.some((i) => url.includes(i))) {
          this.showFooter = false;
          this.cdr.detectChanges();
          return;
        }

        this.showFooter = true;
        this.cdr.detectChanges();
      };

      // Initial check
      checkUrl(this.router.url);

      // Subscribe to router events
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          checkUrl(event.url);
        }
      });
    }
    this.cdr.detectChanges();
  }
}
