import { Injectable, signal } from '@angular/core';
/**
 * @internal
 */
@Injectable({
  providedIn: 'root'
})
export class LabelDesignerService {
  // Create a signal to hold the selected item
  private selectedItemSignal = signal<any>(null);

  // Expose a method to set the selected item using the signal
  selectItem(item: any): void {
    this.selectedItemSignal.set(item); // Set the new value
  }

  // Method to access the selected item signal
  getSelectedItem() {
    return this.selectedItemSignal();
  }

  actionLabelDesignerSignal = signal<string | undefined>(undefined);

  // Expose a method to set the ActionLabel Designer item using the signal
  setActionLabelDesigner(type: string | undefined): void {
    this.actionLabelDesignerSignal.set(type); // Set the new value
  }

  // Method to access the ActionLabel Designer item signal
  getActionLabelDesigner() {
    return this.actionLabelDesignerSignal();
  }

  labelConfig = signal<any>(null);

  // Expose a method to set the ActionLabel Designer item using the signal
  setLabelConfig(type: any): void {
    this.labelConfig.set(type); // Set the new value
  }

  // Method to access the ActionLabel Designer item signal
  getLabelConfig() {
    return this.labelConfig();
  }

  menuItems = signal<any>(null);

  // Expose a method to set the ActionLabel Designer item using the signal
  setMenuItems(menus: {
    availableFields: any;
    HazardAvailableFields: any;
  }): void {
    this.menuItems.set(menus); // Set the new value
  }

  // Method to access the ActionLabel Designer item signal
  getMenuItems() {
    return this.menuItems();
  }
  menuItemsLoader = signal<boolean>(false);

  setMenuItemsLoader(isLoading: boolean): void {
    this.menuItemsLoader.set(isLoading); // Set the new value
  }

  // Method to access the ActionLabel Designer item signal
  getMenuItemsLoader() {
    return this.menuItemsLoader();
  }
}
