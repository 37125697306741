import { Injectable } from '@angular/core';

export interface LabelConfig {
  dpi: number;
  labelWidthInches: number;
  labelHeightInches: number;
  orientation: 'portrait' | 'landscape';
  language: 'zpl' | 'ipl' | 'epl'; // Specify whether ZPL, IPL, or EPL commands should be generated
  config: string; // JSON string representing the label configuration (e.g., label items, styles)
  format: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LabelCommandService {
  public buildLabelFromConfig(labelConfig: LabelConfig): string {
    const labelData = JSON.parse(labelConfig.config);
    const labelItems = labelData.labelItems;
    const labelSettings = labelData.label;
    const format = labelConfig.format;

    console.log('Label itms', labelItems);

    // Convert margins from mm to dots
    const marginLeftDots = this.convertMmToDots(
      labelSettings['marginLeft.mm'],
      labelConfig.dpi
    );
    const marginTopDots = this.convertMmToDots(
      labelSettings['marginTop.mm'],
      labelConfig.dpi
    );

    // Calculate scaling factors
    const widthScalingFactor = this.calculateScalingFactor(
      labelSettings['width.mm'],
      labelConfig.labelWidthInches * 25.4
    );

    const heightScalingFactor = this.calculateScalingFactor(
      labelSettings['height.mm'],
      labelConfig.labelHeightInches * 25.4
    );

    let labelCommand = this.getCommandStart(labelConfig.language);
    //TODO: Test this with different size label paper
    //TODO: uncomment when test is successful for papar size
    //labelCommand += this.getLabelWidthHeight(labelConfig.language, labelSettings['width.mm'], labelSettings['height.mm'], labelConfig.dpi)
    labelCommand += this.getOrientationCommand(
      labelConfig.language,
      labelConfig.orientation
    );
    let lastYPosition = { value: 0 };

    labelItems.forEach((item: any) => {
      // Scale positions and sizes

      //     const xPos =
      //     this.convertMmToDots(item.x, labelConfig.dpi) *
      //     widthScalingFactor;
      // const yPos =
      //     this.convertMmToDots(item.y, labelConfig.dpi) *
      //     heightScalingFactor;
      const xPos = this.convertMmToDots(item.x, labelConfig.dpi);
      const yPos = this.convertMmToDots(item.y, labelConfig.dpi);
      // const heightDots =
      //     this.convertMmToDots(item.style['height.mm'], labelConfig.dpi) *
      //     heightScalingFactor;
      // const fontSizePt = labelSettings['font-size.pt']; // Extract font size from label settings
      // const scaledFontHeight =
      //     this.convertPtToDots(fontSizePt, labelConfig.dpi) *
      //     heightScalingFactor;
      const widthDots = this.convertMmToDots(
        item.style['width.mm'],
        labelConfig.dpi
      );
      const heightDots = this.convertMmToDots(
        item.style['height.mm'],
        labelConfig.dpi
      );
      const fontSizePt = labelSettings['font-size.pt']; // Extract font size from label settings

      const scaledFontHeight = this.convertPtToDots(
        fontSizePt,
        labelConfig.dpi
      );

      // Generate ZPL/other language command
      labelCommand += this.getItemCommand(
        labelConfig.language,
        item.fields[0].type || 'field',
        xPos,
        yPos,
        item.fields[0].prefix || '',
        item.fields[0].content || '',
        `{{${item.fields[0].field}}}`, // Placeholder for the field,
        widthDots,
        heightDots,
        marginLeftDots,
        marginTopDots,
        scaledFontHeight,
        labelConfig.dpi,
        lastYPosition,
        item.fields[0].field_type,
        format,
        item?.fields[0]?.text_type
      );
    });

    labelCommand += this.getCommandEnd(labelConfig.language);

    return labelCommand;
  }

  private calculateScalingFactor(
    contentDimensionMm: number,
    labelDimensionMm: number
  ): number {
    return labelDimensionMm / contentDimensionMm;
  }

  // private convertMmToDots(mm: number, dpi: number): number {
  //     return Math.round(mm * (dpi / 25.4)); // 1 inch = 25.4 mm
  // }
  private convertMmToDots(mm: number, dpi: number): number {
    const scalingFactor = 0.65;
    return Math.round(mm * (dpi / 25.4) * scalingFactor);
  }

  private convertPtToDots(pt: number, dpi: number): number {
    return Math.round(pt * (dpi / 72)); // 1 point = 1/72 inch
  }

  private getCommandStart(language: 'zpl' | 'ipl' | 'epl'): string {
    if (language === 'zpl') {
      return '^XA'; // Start ZPL command
    } else if (language === 'ipl') {
      return 'L'; // Start IPL command
    } else if (language === 'epl') {
      return 'N\n'; // Start EPL command
    }
    return '';
  }

  //This method is to modify the label paper size based on height width in mm
  // and dpi
  private getLabelWidthHeight(
    language: 'zpl' | 'ipl' | 'epl',
    labelWidthMm: number,
    labelHeightMm: number,
    dpi: number
  ): string {
    const mmToDots = (mm: number) => Math.round(mm * (dpi / 25.4));

    if (language === 'zpl') {
      // Maximum width and height based on the printer's DPI
      const maxWidthDots =
        dpi === 203 ? 406 : dpi === 300 ? 600 : dpi === 600 ? 1200 : 406; // Adjustable for DPI
      const maxHeightDots =
        dpi === 203 ? 6500 : dpi === 300 ? 9600 : dpi === 600 ? 19200 : 6500;

      // Convert width and height from mm to dots
      let widthDots = mmToDots(labelWidthMm);
      let heightDots = mmToDots(labelHeightMm);

      // Clamp width and height to the printer's maximum dimensions
      widthDots = Math.min(widthDots, maxWidthDots);
      heightDots = Math.min(heightDots, maxHeightDots);

      // Log warnings if dimensions are clamped
      if (widthDots > maxWidthDots) {
        console.warn(
          `Width exceeds maximum (${maxWidthDots} dots). Adjusted to ${maxWidthDots}.`
        );
      }
      if (heightDots > maxHeightDots) {
        console.warn(
          `Height exceeds maximum (${maxHeightDots} dots). Adjusted to ${maxHeightDots}.`
        );
      }

      return `^PW${widthDots}^LL${heightDots}`;
    } else if (language === 'ipl') {
      return 'L'; // Start IPL command
    } else if (language === 'epl') {
      return 'N\n'; // Start EPL command
    }

    return '';
  }

  private getOrientationCommand(
    language: 'zpl' | 'ipl' | 'epl',
    orientation: 'portrait' | 'landscape'
  ): string {
    if (orientation === 'landscape') {
      if (language === 'zpl') {
        return '^FWB'; // ZPL rotate for landscape
      } else if (language === 'ipl') {
        return 'R270'; // IPL rotate for landscape
      } else if (language === 'epl') {
        return 'ZB\n'; // EPL rotate for landscape (Zebra EPL)
      }
    }
    return '';
  }

  public getItemCommand(
    language: string,
    itemType: string,
    xPos: number,
    yPos: number,
    prefix: string,
    label: string,
    content: string,
    widthDots: number,
    heightDots: number,
    marginLeftDots: number,
    marginTopDots: number,
    fontHeight: number,
    dpi: number,
    lastYPosition: { value: number }, // Track the last Y position,
    field_type: string,
    format: boolean,
    fieldType: string
  ): string {
    console.log('this log content ', content);
    let command = '';

    // Clean up the prefix by removing HTML tags and entities
    // let cleanedPrefix = this.stripHtmlTags(prefix).replace(/&nbsp;/g, '');
    let cleanedPrefix = label;
    console.log('this log label ', label);
    // Adjust the x position for the content to be placed next to the prefix
    const contentXPos = xPos + cleanedPrefix.length * fontHeight * 0.3 + 10;

    // cleanedPrefix = this.truncateTextToFitWidth(cleanedPrefix, widthDots, fontHeight);
    // console.log('cleanedPrefix', cleanedPrefix);

    // Handle 'product name' separately to place content on a new line
    if (label.toLowerCase() === 'product name') {
      if (language === 'zpl') {
        // command += `^FO${xPos + marginLeftDots},${yPos + marginTopDots
        //     }^A0N,${fontHeight},${fontHeight * 0.8}^FD${cleanedPrefix}^FS`;
        // const newYPos = yPos + fontHeight + 10;
        // command += `^FO${xPos + marginLeftDots},${newYPos + marginTopDots
        //     }^A0N,${fontHeight},${fontHeight * 0.8}^FD${content}^FS`;
        // lastYPosition.value = newYPos;
        command += `^FO${xPos + marginLeftDots},${yPos + marginTopDots}^A0N,${
          fontHeight * 0.72
        },${fontHeight * 0.72},^FD${cleanedPrefix}^FS`;
      } else if (language === 'ipl') {
        command += `A${xPos + marginLeftDots},${
          yPos + marginTopDots
        },0,1,1,1,N,"${cleanedPrefix}"`;
        const newYPos = yPos + fontHeight + 10;
        command += `A${xPos + marginLeftDots},${
          newYPos + marginTopDots
        },0,1,1,1,N,"${content}"`;
        lastYPosition.value = newYPos;
      } else if (language === 'epl') {
        command += `A${xPos},${yPos},0,${
          fontHeight * 0.8
        },${fontHeight},N,"${cleanedPrefix}:"\n`;
        const newYPos = yPos + fontHeight + 10;
        command += `A${xPos},${newYPos},0,${
          fontHeight * 0.8
        },${fontHeight},N,"${content}"\n`;
        lastYPosition.value = newYPos;
      }
    } else {
      // Handle other item types: barcode, image, date, text, etc.
      if (itemType === 'barcode') {
        // Logic for centering the barcode
        const availableWidthDots = widthDots;
        const barcodeWidthEstimate = content.length * 10;
        const centeredXPos = Math.max(
          (availableWidthDots - barcodeWidthEstimate) / 2,
          0
        );

        if (language === 'zpl') {
          // Add the text (prefix) above the barcode
          command += `^FO${centeredXPos + marginLeftDots},${
            yPos + marginTopDots
          }^A0N,${fontHeight},${fontHeight * 0.8}^FD${content}^FS`;

          // Adjust barcode width using ^BY and center it
          command += `^FO${centeredXPos + marginLeftDots},${
            yPos + marginTopDots + fontHeight + 5
          }^BY1,2,50^BCN,100,Y,N^FD${content}^FS`; // Adjust BY params as needed

          lastYPosition.value = yPos + fontHeight + 120; // Update the last Y position after the barcode
        } else if (language === 'ipl') {
          // Add the text (prefix) above the barcode
          command += `A${centeredXPos + marginLeftDots},${
            yPos + marginTopDots
          },0,1,1,1,N,"${content}"`;

          // Generate the barcode below the text and center it
          command += `B${centeredXPos + marginLeftDots},${
            yPos + marginTopDots + fontHeight + 5
          },0,1,1,1,N,"${content}"`;

          lastYPosition.value = yPos + fontHeight + 120;
        } else if (language === 'epl') {
          // Add the text (prefix) above the barcode
          command += `A${centeredXPos},${yPos},0,${
            fontHeight * 0.8
          },${fontHeight},N,"${content}"\n`;

          // Generate the barcode below the text and center it
          command += `B${centeredXPos},${
            yPos + fontHeight + 5
          },2,1,2,3,50,N,"${content}"\n`;

          lastYPosition.value = yPos + fontHeight + 120;
        }
      } else if (itemType == 'qr-code') {
        if (language === 'zpl') {
          if (format) {
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^BQN,2,4 ^FDLA,${cleanedPrefix}`;
          } else {
            let commandValue =
              cleanedPrefix == '1A4FF0100000522000000007'
                ? content
                : cleanedPrefix;
            // Adjust barcode width using ^BQ and center it
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^BQN,2,4 ^FDLA,${commandValue}`;
          }
        }
      } else if (itemType === 'img') {
        // Handle image fields
        if (language === 'zpl') {
          command += `^FO${xPos + marginLeftDots},${
            yPos + marginTopDots
          }^GFA,${content}^FS`;
        } else if (language === 'ipl') {
          command += `GW${xPos + marginLeftDots},${yPos + marginTopDots},${
            widthDots / 8
          },${heightDots},${content}`;
        } else if (language === 'epl') {
          command += `GK${xPos},${yPos},${content}\n`;
        }
        lastYPosition.value = yPos + heightDots + 10; // Image height + padding
      } else if (itemType === 'date') {
        // Handle date fields
        const formattedDate = content;
        if (language === 'zpl') {
          if (format) {
            // command += `^FO${xPos + marginLeftDots},${yPos + marginTopDots
            //     }^A0N,${fontHeight},${fontHeight * 0.8
            //     },^TBN,${widthDots},${fontHeight}^FD${cleanedPrefix}^FS`;
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${
              fontHeight * 0.72
            },^FD${cleanedPrefix}^FS`;
          }
          if (field_type == 'dynamic' && !format) {
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${
              fontHeight * 0.72
            }^FD${cleanedPrefix}^FS`;
          }
          if (field_type == 'static' && !format) {
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${fontHeight * 0.72}^FD${content}^FS`;
          }

          // command += `^FO${xPos + marginLeftDots},${yPos + marginTopDots
          //     }^A0N,${fontHeight},${fontHeight * 0.8
          //     }^FD${cleanedPrefix}^FS`;
          // command += `^FO${contentXPos + marginLeftDots},${yPos + marginTopDots
          //     }^A0N,${fontHeight},${fontHeight * 0.8
          //     }^FD${formattedDate}^FS`;
        } else if (language === 'ipl') {
          command += `A${xPos + marginLeftDots},${
            yPos + marginTopDots
          },0,1,1,1,N,"${cleanedPrefix}"`;
          command += `A${contentXPos + marginLeftDots},${
            yPos + marginTopDots
          },0,1,1,1,N,"${formattedDate}"`;
        } else if (language === 'epl') {
          command += `A${xPos},${yPos},0,${
            fontHeight * 0.8
          },${fontHeight},N,"${cleanedPrefix}:"\n`;
          command += `A${contentXPos},${yPos},0,${
            fontHeight * 0.8
          },${fontHeight},N,"${formattedDate}"\n`;
        }
      } else if (fieldType == 'message') {
        const blockWidthDots = 400;
        const maxCharsPerLine = this.calculateMaxCharsPerLine(
          blockWidthDots,
          fontHeight,
          dpi
        );
        const wrappedLines = this.wrapContentText(
          cleanedPrefix,
          maxCharsPerLine
        );
        const numberOfLines = wrappedLines.length;
        const lineSpacing = 0; // Adjust if needed

        command += `^FO${xPos + marginLeftDots + 1},${
          yPos + marginTopDots
        }^A0N,${fontHeight * 0.72},${
          fontHeight * 0.72
        },^FB${blockWidthDots},${numberOfLines},${lineSpacing},L,0\n^FD${cleanedPrefix}^FS`;
      } else {
        // Handle general text fields
        if (language === 'zpl') {
          if (format) {
            // command += `^FO${xPos + marginLeftDots},${yPos + marginTopDots
            //     }^A0N,${fontHeight},${fontHeight * 0.8
            //     },^TBN,${widthDots},${fontHeight}^FD${cleanedPrefix}^FS`;
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${
              fontHeight * 0.72
            },^FD${cleanedPrefix}^FS`;
          }
          if (field_type == 'dynamic' && !format) {
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${
              fontHeight * 0.72
            }^FD${cleanedPrefix}^FS`;
          }
          if (field_type == 'static' && !format) {
            command += `^FO${xPos + marginLeftDots},${
              yPos + marginTopDots
            }^A0N,${fontHeight * 0.72},${fontHeight * 0.72}^FD${content}^FS`;
          }
        } else if (language === 'ipl') {
          command += `A${xPos + marginLeftDots},${
            yPos + marginTopDots
          },0,1,1,1,N,"${cleanedPrefix}"`;
          command += `A${contentXPos + marginLeftDots},${
            yPos + marginTopDots
          },0,1,1,1,N,"${content}"`;
        } else if (language === 'epl') {
          command += `A${xPos},${yPos},0,${
            fontHeight * 0.8
          },${fontHeight},N,"${cleanedPrefix}:"\n`;
          command += `A${contentXPos},${yPos},0,${
            fontHeight * 0.8
          },${fontHeight},N,"${content}"\n`;
        }
        lastYPosition.value = yPos;
      }
    }

    return command;
  }

  private getCommandEnd(language: 'zpl' | 'ipl' | 'epl'): string {
    if (language === 'zpl') {
      return '^XZ'; // End ZPL command
    } else if (language === 'ipl') {
      return ''; // End IPL command
    } else if (language === 'epl') {
      return 'P1\n'; // Print EPL command
    }
    return '';
  }

  private calculateFieldMmToDots(mm: number, dpi: number): number {
    return (mm / 25.4) * dpi; // 1 inch = 25.4 mm
  }

  private stripHtmlTags(input: string): string {
    return input.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
  }
  truncateTextToFitWidth(
    text: string,
    maxWidth: number,
    fontHeight: number,
    fontWidthFactor = 0.8
  ) {
    const fontWidth = fontHeight * fontWidthFactor; // Approximate character width in dots
    const maxChars = Math.round(maxWidth / fontWidth); // Max characters that fit within width
    return text.length > maxChars ? text.slice(0, maxChars) : text; // Truncate if necessary
  }

  private calculateMaxCharsPerLine(
    blockWidthDots: number,
    fontHeight: number,
    dpi: number
  ): number {
    const charWidthDots = fontHeight / 2; // Estimate character width
    return Math.floor(blockWidthDots / charWidthDots); // Max characters per line
  }
  private wrapContentText(text: string, maxCharsPerLine: number) {
    const words = text.split(' ');
    let lines = [];
    let currentLine = '';

    for (const word of words) {
      if ((currentLine + word).length > maxCharsPerLine) {
        lines.push(currentLine.trim());
        currentLine = word + ' ';
      } else {
        currentLine += word + ' ';
      }
    }

    lines.push(currentLine.trim());
    return lines;
  }
}
