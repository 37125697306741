import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import {
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
  throwError
} from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { LoaderService } from 'src/app/service/loader/loader.service';
import { environment } from 'src/assets/environments/environment.prod';
import { SelectImageDialogComponent } from 'src/app/core/shared/select-image-dialog/select-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HazardSymbolService } from '../hazard-symbol/hazard-symbol.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ProgramConfigService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
    private loaderService: LoaderService,
    private hazardSymbolService: HazardSymbolService,
    private dialog: MatDialog
  ) {}

  getProgramConfig(queryParams: any): Observable<any> {
    let params = this.appSettingsService.queryStringFormat(queryParams);

    return this.http.get('program-config?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: any = {
              id: parseInt(item.id),
              name: item.name,
              programNumber: item.programNumber,
              stripeConfigured: item.stripeConfigured ? 'Success' : 'Pending',
              createdAt: moment(item.createdAt).format('YYYY MMMM DD'),
              updatedAt: moment(item.updatedAt).format('YYYY MMMM DD')
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  createProgramConfig(data: any) {
    this.programConfigCache = [];
    return this.http.post('program-config', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getByProgramConfigId(id: any): Observable<any> {
    return this.http.get('program-config/' + id).pipe(
      map((resp: any) => {
        let ProgramConfig: any;
        if (resp && resp.data) {
          let attachment = resp.data?.attachment[0];
          let imageUrl =
            environment?.mediaUrl +
            attachment?.dir +
            '/' +
            attachment?.fileName;
          let imageFileName = attachment?.originalFileName;

          // let LicenseAgreementAttachment =
          //   resp.data?.licenseAgreementFormId?.attachment?.find(
          //     (i: any) => i.class == 'LicenseAgreement'
          //   );
          let licensePath = resp.data?.licenseAgreementFormId?.attachment;
          let pdfUrl =
            environment.mediaUrl +
            licensePath?.dir +
            '/' +
            licensePath?.fileName;
          let pdfFileName = licensePath?.originalFileName;

          let programLogoAttach = resp.data?.attachment?.find(
            (i: any) => i.class == 'programBrandLogo'
          );
          let logo =
            environment.mediaUrl +
            programLogoAttach?.dir +
            '/' +
            programLogoAttach?.fileName;
          let data = {
            imageUrl: imageUrl,
            imageFileName: imageFileName,
            licenseAgreementPdfUrl: pdfUrl,
            licenseAgreementPdfFileName: pdfFileName,
            logoUrl: logo,
            ...resp.data
          };
          ProgramConfig = data;
        }
        return ProgramConfig;
      })
    );
  }

  updateProgramConfig(id: any, data: any) {
    this.programConfigCache = [];
    return this.http.put('program-config/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteProgramConfig(id: any) {
    this.programConfigCache = [];
    return this.http.delete('program-config/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  postAttachment(data: any) {
    return this.http.post('attachment', data).pipe(
      map(
        (resp: any) => {
          return resp.data || null;
        },
        (err: any) => {
          this.handleError(err);
        }
      )
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  public programConfigCache: any = {};
  getProgramConfigAll(): Observable<any> {
    // if (
    //   Array.isArray(this.programConfigCache?.items) &&
    //   this.programConfigCache?.items?.length > 0
    // ) {
    //   return of(this.programConfigCache); // Return cached data
    // } else {
    return this.http.get('program-config', httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let attachment = item?.attachment?.find(
              (i: any) => i.class == 'ProgramConfig'
            );

            let imageUrl =
              environment.mediaUrl +
              attachment?.dir +
              '/' +
              attachment?.fileName;

            let LicenseAgreementAttachment =
              item?.licenseAgreementFormId?.attachment;
            let pdfUrl =
              environment.mediaUrl +
              LicenseAgreementAttachment?.dir +
              '/' +
              LicenseAgreementAttachment?.fileName;
            let pdfFileName = LicenseAgreementAttachment?.originalFileName;
            let sampleManagementList = JSON.parse(item.sampleManagement);
            let temp: any = {
              ...item,
              id: parseInt(item.id),
              programName: item.name,
              imageUrl: imageUrl,
              licenseAgreementForm: pdfUrl,
              sampleManagementList: sampleManagementList,
              programContentUrl: item.programContentURL,
              backgroundColor: item.backgroundColor,
              programConfigId: parseInt(item.id),
              creditCardProcessingFeePercentage: parseFloat(
                item?.creditCardProcessingFeePercentage
              ),
              financingPlanPercentage: parseFloat(
                item?.financingPlanPercentage
              ),
              latePaymentFeePercentage: parseFloat(
                item?.latePaymentFeePercentage
              )
            };
            let bankInfo = {
              bankName: item.bankName,
              bankAddress: item.bankAddress,
              domesticAchRoutingNumber: item.domesticAchRoutingNumber,
              wireRoutingNumber: item.wireRoutingNumber,
              wireSwiftCode: item.wireSwiftCode,
              accountName: item.accountName,
              accountNumber: item.accountNumber
            };
            temp.bankInfo = bankInfo;
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
          this.programConfigCache = retData;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }
        return retData;
      })
    );
    // }
  }

  applyLicenseAgreement(data: any) {
    return this.http.post('agreement', data).pipe(
      map((resp: any) => resp.data || null),
      catchError((err: any) => {
        this.handleError(err);
        return of(null); // Return a fallback value if needed
      })
    );
  }

  getProgramApprovalStatus(id: any, params?: any): Observable<any> {
    const paramString = params
      ? `?${this.appSettingsService.queryStringFormatWithoutOffset(params)}`
      : '';

    const url = `agreement/${id}${paramString}`;
    return this.http.get(url, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let agreementFormFq = item.attachment.find(
              (i: any) => i.class == 'AgreementByFQ'
            );
            let attachmentByCompany = item.attachment.find(
              (i: any) => i.class == 'AgreementByCompany'
            );
            let attachmentAcceptFQ = item.attachment.find(
              (i: any) => i.class == 'AgreementAcceptByFQ' // New attachment
            );

            let agreementForm =
              environment.mediaUrl +
              agreementFormFq?.dir +
              '/' +
              agreementFormFq?.fileName;
            let agreementFormAcceptFQ =
              environment.mediaUrl +
              attachmentAcceptFQ?.dir +
              '/' +
              attachmentAcceptFQ?.fileName; // New RL
            let agreementByCompany =
              environment.mediaUrl +
              attachmentByCompany?.dir +
              '/' +
              attachmentByCompany?.fileName;
            let temp: any = {
              ...item,
              ...item.companyId,
              id: item.id,
              licenseFee: parseFloat(item.LicenseFee),
              LicenseFee: parseFloat(item.LicenseFee),
              name: item.type,
              date: moment(item.createdAt).format('YYYY MMMM DD'),
              uploadedFile: agreementForm,
              LicenseRenewalFee: parseFloat(item.LicenseRenewalFee),
              agreementFormFq: agreementForm, // Add if needed
              agreementFormAcceptFQ: agreementFormAcceptFQ,
              agreementByCompany: agreementByCompany,
              agreementForm: item.isApprove
                ? agreementForm
                : item.isFormSubmitted
                ? agreementByCompany
                : agreementFormAcceptFQ,

              creditCardProcessingFeePercentage: parseFloat(
                item.programConfigId?.creditCardProcessingFeePercentage
              ),
              financingPlanPercentage: parseFloat(
                item.programConfigId?.financingPlanPercentage
              ),
              latePaymentFeePercentage: parseFloat(
                item.programConfigId?.latePaymentFeePercentage
              ),
              agreementReqDate:
                moment(item.createdAt).format('YYYY MMMM DD') || '-',
              agreementRecDate:
                moment(item.Form02RecDate).format('YYYY MMMM DD') || '-',
              agreementResDate:
                moment(item.Form02RespDate).format('YYYY MMMM DD') || '-',
              agreementRes: item.Form02RespNote,
              agreementExpDate:
                moment(item.Form02ExpDate).format('YYYY MMMM DD') || '-'
            };
            let bankInfo = {
              bankName: item.programConfigId.bankName,
              bankAddress: item.programConfigId.bankAddress,
              domesticAchRoutingNumber:
                item.programConfigId.domesticAchRoutingNumber,
              wireRoutingNumber: item.programConfigId.wireRoutingNumber,
              wireSwiftCode: item.programConfigId.wireSwiftCode,
              accountName: item.programConfigId.accountName,
              accountNumber: item.programConfigId.accountNumber
            };
            temp.bankInfo = bankInfo;
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  getAgreementStatus(
    id: any,
    type: string,
    companyId: string,
    programConfigId: string
  ): Observable<any> {
    return this.http
      .get(
        `agreement/retrive/${id}?type=${type}&companyId=${companyId}&programConfigId=${programConfigId}`,
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          let retData: any;
          if (resp && resp.data) {
            let item = resp.data;
            let attachment = item.attachment.find(
              (i: any) => i.class == 'AgreementByFQ'
            );
            let agreementForm =
              environment.mediaUrl +
              attachment?.dir +
              '/' +
              attachment?.fileName;
            let temp: any = {
              agreementForm: agreementForm,
              ...item
            };
            retData = temp;
          }

          return retData;
        })
      );
  }

  createCompanyContactInfo(data: any) {
    this.programConfigCache = [];
    return this.http.post('contactInformation', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getProgramDetailsOverview(id: number): Observable<any> {
    return this.http.get(`program-config/overview/${id}`).pipe(
      map((resp: any) => {
        if (!resp || !resp.data) {
          throw new Error('Invalid response or missing data');
        }

        return { resp: resp.data };
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getProgramLabelConfig(
    programConfigId: number,
    type: string,
    source: string
  ): Observable<any> {
    return this.http
      .get(
        `program-config/label-config/${programConfigId}?type=${type}&source=${source}`
      )
      .pipe(
        map((resp: any) => {
          if (!resp || !resp.data) {
            throw new Error('Invalid response or missing data');
          }

          return { resp: resp.data };
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getLabelConfigPrepare(config: any, printData: any): any {
    config.labelItems = config.labelItems.filter((item: any) => {
      // Check all fields of the current item
      let shouldKeepItem = true;

      item.fields.forEach((field: any) => {
        // Check if the field exists in printData and is not hazard-related
        if (!field?.isHazard && printData.hasOwnProperty(field.field)) {
          // Update the field content with the corresponding value from printData
          field.content = printData[field.field];
        }

        // If isHazard is true and printData doesn't have the field, mark the item for removal
        if (field?.isHazard && !printData.hasOwnProperty(field.field)) {
          shouldKeepItem = false;
        }
      });

      // Return true if the item should be kept, false if it should be removed
      return shouldKeepItem;
    });

    return config;
  }

  async selectHazard(): Promise<any> {
    try {
      this.loaderService.show();

      let param = {
        filter: JSON.stringify({
          where: {
            isActive: 'true'
          }
        })
      };

      // Fetch the hazard symbols
      const hazardResp = await firstValueFrom(
        this.hazardSymbolService.getAllHazardSymbol(param)
      );
      this.loaderService.hide();

      // Open the dialog and pass the data
      const dialogRef = this.dialog.open(SelectImageDialogComponent, {
        data: {
          imageData: hazardResp.items.map((item: any) => ({
            ...item,
            id: this.appSettingsService.generate16DigitUUID(),
            url: item?.label
          })),
          title: 'Select Hazard Symbol'
        },
        width: '60%'
      });

      // Return a Promise that resolves when the dialog is closed
      return new Promise((resolve, reject) => {
        dialogRef.afterClosed().subscribe(
          (selectedImage: any) => {
            if (selectedImage) {
              resolve(selectedImage); // Resolve with the selected image
            } else {
              resolve([]); // Resolve with null if no image was selected
            }
          },
          (error) => {
            reject(error); // Reject the promise if there is an error
          }
        );
      });
    } catch (err) {
      this.loaderService.hide();
      console.error('Error in selectHazard method', err);
      throw err; // Rethrow the error to handle it in the calling code
    }
  }

  getNameContentMap(hazardSymbols: any[] = []) {
    console.log(hazardSymbols);
    if (hazardSymbols && hazardSymbols?.length == 0) {
      return {};
    }
    // Create a map of name to content
    return hazardSymbols.reduce(
      (acc, label) => {
        acc[label.name] = label.content;
        return acc;
      },
      {} as { [key: string]: string }
    );
  }
}
