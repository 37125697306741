import { Component, Inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ImageGalleryMerfor } from '../image-gallery-merfor/image-gallery-merfor.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vex-select-image-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    CommonModule,
    ImageGalleryMerfor,
    MatTooltipModule,
    MatButtonModule
  ],
  templateUrl: './select-image-dialog.component.html',
  styleUrls: ['./select-image-dialog.component.scss']
})
export class SelectImageDialogComponent {
  imageData: any[] = [];
  selectedImage = signal<any>([]);
  title = signal<string>('Select Image');
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { imageData: any[]; title: string },
    private dialogRef: MatDialogRef<SelectImageDialogComponent>,
    private toastr: ToastrService
  ) {
    this.imageData = data?.imageData;
    this.title.set(data?.title || 'Select Image');
  }

  onSubmit() {
    this.dialogRef.close(this.selectedImage());
  }

  onSelectImage(image: any) {
    const selectedImages = this.selectedImage();
    const imageIndex = selectedImages.findIndex(
      (img: any) => img.id == image.id
    );

    if (imageIndex !== -1) {
      // If the image is already selected, remove it (unselect it)
      selectedImages.splice(imageIndex, 1);
    } else {
      // If the image is not selected, add it to the array
      selectedImages.push(image);
    }

    // Update the signal with the new array
    this.selectedImage.set([...selectedImages]);
  }

  // Helper method to check if an image is selected
  isImageSelected(image: any): boolean {
    return this.selectedImage().some((img: any) => img.id == image.id);
  }
}
