import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatRippleModule, MatIconModule, CommonModule]
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean = false;
  userInfo: any;
  userprofileURL: any;
  defaultImageUrl: string = 'assets/img/icons/error-User.svg';

  constructor(
    private popover: VexPopoverService,
    private cdr: ChangeDetectorRef,
    public appSettingsService: AppSettingsService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.userInfo = this.appSettingsService.userData();

    this.userprofileURL = this.appSettingsService.userData().userImgUrl;
  }
  ngAfterViewInit() {
    this.dataService.getUserProfileObservable().subscribe((resp) => {
      if (resp) {
        this.dataService.triggerUserProfileRefresh(false);
        this.userprofileURL = this.dataService.getUserProfileDataUrl();
        this.userInfo = this.appSettingsService.userData();
        this.cdr.detectChanges();
      }
    });
  }
  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cdr.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cdr.markForCheck();
    });
  }

  navigate() {
    this.router.navigate(['login']);
  }
  handleImageError(event: any) {
    // Set the source of the image to the default image path
    event.target.src = this.defaultImageUrl;
  }
}
