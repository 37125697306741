<div
  class="flex-col gap-1 w-full py-[10px]"
  [class.footer-open]="sidenavOpen$ | async"
  [class.container]="!(sidenavOpen$ | async)">
  <div class="flex flex-row justify-between items-center">
    <div class="flex flex-row gap-1 justify-center items-center">
      <a [routerLink]="['/home']">
        <img
          src="assets/img/logo/CQA_color_logo.png"
          class="h-10 object-contain" />
      </a>
      <div class="font-normal text-[13px] hidden sm:block text-gray-400">
        2024 © All rights reserved. This site is operated by The Center for
        Quality Assurance
      </div>
    </div>
    <div class="flex flex-row items-center gap-4 z-0">
      <button
        mat-button
        style="text-wrap-mode: nowrap"
        color="primary"
        (click)="openCMS('privacypolicy')">
        Privacy Policy
      </button>
      <button
        mat-button
        style="text-wrap-mode: nowrap"
        color="primary"
        (click)="openCMS('termsconditions')">
        Terms & Conditions
      </button>
    </div>
  </div>
</div>
