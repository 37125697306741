import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { ApiService } from './google-api-service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CurrencyFormatDirective } from 'src/app/directives/usd-format.directive';
import { RoundedUSDFormat } from 'src/app/directives/rounded-usd-format.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SweetAlert2Module,
    CurrencyFormatDirective,
    RoundedUSDFormat
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SweetAlert2Module,
    CurrencyFormatDirective,
    RoundedUSDFormat
  ],
  providers: [ApiService],
  declarations: [
  ]
})
export class SharedModule {}
