<!-- <table style="width: 230px" class="user-input">
    <tr>
        <td>{{ "Magnify editor by" | translate }}:</td>
        <td>
            <select
                class="form-control"
                (change)="change('magnification', +$event.target.value)"
            >
                <option
                    *ngFor="let mag of magnifications"
                    [value]="mag"
                    [selected]="mag === viewSettings.magnification"
                >
                    {{ mag }}x
                </option>
            </select>
        </td>
    </tr>
    <tr>
        <td>{{ "Magnify preview by" | translate }}:</td>
        <td>
            <select
                class="form-control"
                (change)="change('previewMagnification', +$event.target.value)"
            >
                <option
                    *ngFor="let mag of magnifications"
                    [value]="mag"
                    [selected]="mag === viewSettings.previewMagnification"
                >
                    {{ mag }}x
                </option>
            </select>
        </td>
    </tr>
</table>
<table class="icon">
    <tr class="link" (click)="change('fullscreen', !viewSettings.fullscreen)">
        <td>
            <ll-icon
                [type]="'check'"
                [size]="20"
                *ngIf="viewSettings.fullscreen"
            ></ll-icon>
        </td>
        <td>{{ "Fullscreen" | translate }}</td>
    </tr>
</table>
<h3></h3>
<table class="icon">
    <tr class="link" (click)="change('gridVisible', !viewSettings.gridVisible)">
        <td>
            <ll-icon
                [type]="'check'"
                [size]="20"
                *ngIf="viewSettings.gridVisible"
            ></ll-icon>
        </td>
        <td colspan="3">
            {{ "Show grid" | translate }}
        </td>
    </tr>
    <tr
        class="link"
        *ngFor="let g of grid"
        (click)="change('grid', viewSettings.grid === g ? undefined : g)"
    >
        <td>
            <ll-icon
                [type]="'check'"
                [size]="20"
                *ngIf="viewSettings.grid === g"
            ></ll-icon>
        </td>
        <td style="text-align: right">{{ g }}</td>
        <td>&nbsp;mm</td>
        <td style="width: 184px"></td>
    </tr>
</table> -->

<div class="flex flex-col gap-2 px-3 py-8">
  <div class="flex flex-row items-center justify-between px-4">
    <div>{{ "Magnify editor by" | translate }}:</div>
    <div>
      <select class="form-control cursor-pointer">
        <!-- (change)="change('magnification', +$event.target.value)" -->
        <option
          *ngFor="let mag of magnifications"
          [value]="mag"
          [selected]="mag === viewSettings.magnification">
          {{ mag }}x
        </option>
      </select>
    </div>
  </div>
  <div class="flex flex-row items-center justify-between px-4">
    <div>{{ "Magnify preview by" | translate }}:</div>
    <div>
      <select class="form-control cursor-pointer">
        <!-- (change)="change('previewMagnification', +$event.target.value)" -->
        <option
          *ngFor="let mag of magnifications"
          [value]="mag"
          [selected]="mag === viewSettings.previewMagnification">
          {{ mag }}x
        </option>
      </select>
    </div>
  </div>
  <div
    class="flex flex-row items-center justify-between hover:bg-zinc-100 rounded-md cursor-pointer px-4 py-1">
    <div>
      <ll-icon
        [type]="'check'"
        [size]="20"
        *ngIf="viewSettings.fullscreen"></ll-icon>
    </div>
    <div (click)="change('fullscreen', !viewSettings.fullscreen)" class="mb-2">
      <mat-icon [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
      {{ "Fullscreen" | translate }}
    </div>
  </div>
  <hr class="my-2" />
  <div
    class="flex flex-row items-center justify-between hover:bg-zinc-100 rounded-md cursor-pointer px-4 py-2"
    (click)="change('gridVisible', !viewSettings.gridVisible)">
    <div>
      <ll-icon
        [type]="'check'"
        [size]="20"
        *ngIf="viewSettings.gridVisible"></ll-icon>
    </div>
    <div>{{ "Show grid" | translate }}</div>
  </div>
  <div
    class="flex flex-row items-center justify-between hover:bg-zinc-100 rounded-md cursor-pointer px-4 py-2"
    *ngFor="let g of grid"
    (click)="change('grid', viewSettings.grid === g ? undefined : g)">
    <div>
      <ll-icon
        [type]="'check'"
        [size]="20"
        *ngIf="viewSettings.grid === g"></ll-icon>
    </div>
    <div class="flex flex-row items-center justify-between w-20">
      <div class="w-8 text-right">{{ g }}</div>
      <div>mm</div>
    </div>
  </div>
</div>
