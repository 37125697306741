<!-- <div class="ll-setting-row">
  {{ 'Margins' | translate }}
  <table class="margins">
    <tr>
      <td>{{ 'top' | translate }}:</td>
      <td>
        <input class="form-control input-sm md-input" type="text" [value]="top" (change)="change('top', $event.target.value)">
      </td>
      <td>mm.</td>
    </tr>
    <tr>
      <td>{{ 'right' | translate }}:</td>
      <td>
        <input class="form-control input-sm md-input" type="text" [value]="right" (change)="change('right', $event.target.value)">
      </td>
      <td>mm.</td>
    </tr>
    <tr>
      <td>{{ 'bottom' | translate }}:</td>
      <td>
        <input class="form-control input-sm md-input" type="text" [value]="bottom" (change)="change('bottom', $event.target.value)">
      </td>
      <td>mm.</td>
    </tr>
    <tr>
      <td>{{ 'left' | translate }}:</td>
      <td>
        <input class="form-control input-sm md-input" type="text" [value]="left" (change)="change('left', $event.target.value)">
      </td>
      <td>mm.</td>
    </tr>
  </table>
</div> -->

<div class="flex flex-col gap-2 px-3">
  <div class="text-blue-600 heading">
    {{ "Margins" | translate }}
  </div>

  <div class="flex gap-4">
    <div class="flex flex-col text-left">
      {{ "Top" | translate }}
      <div class="relative">
        <input
          class="form-control w-18"
          type="text"
          maxlength="1000"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
          [value]="top"
          (change)="change('top', $event)" />
        <span class="absolute top-1 right-0.5 text-sm">mm.</span>
      </div>
    </div>
    <div class="flex flex-col text-left">
      {{ "Right" | translate }}
      <div class="relative">
        <input
          class="form-control w-18"
          type="text"
          maxlength="1000"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
          [value]="right"
          (change)="change('right', $event)" />
        <span class="absolute top-1 right-0.5 text-sm">mm.</span>
      </div>
    </div>
    <div class="flex flex-col text-left">
      {{ "Bottom" | translate }}
      <div class="relative">
        <input
          class="form-control w-18"
          type="text"
          maxlength="1000"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
          [value]="bottom"
          (change)="change('bottom', $event)" />
        <span class="absolute top-1 right-0.5 text-sm">mm.</span>
      </div>
    </div>
    <div class="flex flex-col text-left">
      {{ "Left" | translate }}
      <div class="relative">
        <input
          class="form-control w-18"
          type="text"
          maxlength="1000"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').slice(0, 4);"
          [value]="left"
          (change)="change('left', $event)" />
        <span class="absolute top-1 right-0.5 text-sm">mm.</span>
      </div>
    </div>
  </div>
</div>
