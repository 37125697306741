import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  ILabelField,
  ILabelItem,
  ILabelStyle,
  ISetup,
  TLabelLocation
} from '../../label-designer.interface';
import { LabelService } from '../../label.service';
import { TranslateService } from '../../translate/translate.service';
import { LabelMakerFacade } from '../label-maker.facade';

/**
 * @internal
 */
@Component({
  selector: 'll-label-editor',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelEditorComponent {
  _setup: ISetup | any;
  _magnification = 2;
  _magnifiedStyle: ILabelStyle;
  init = false;
  minSize = 10;
  _selectedLabelItem: ILabelItem;

  @Input() grid: number | any;
  @Input() gridVisible: boolean | any;
  @Input() active: ILabelItem | any;
  @Input() backSide = false;
  @Output() activeChange = new EventEmitter<ILabelItem>();
  @Output() setupChange = new EventEmitter<ISetup>();
  @Output() setupChange1 = new EventEmitter<ISetup>();
  @Output() filedDelete = new EventEmitter<any>();
  @Output() showSettings = new EventEmitter<ILabelItem>();
  @Output() done = new EventEmitter<void>();
  field: any;
  constructor(
    private labelService: LabelService,
    private translateService: TranslateService,
    private labelMakerFacade: LabelMakerFacade
  ) {
    this.init = labelService.hasRation();
  }

  @Input() set selectedLabelItem(item: ILabelItem) {
    this._selectedLabelItem = item;
  }
  @Input()
  // set setup(setup: ISetup) {
  //     this._setup = setup;
  //     this.recalculate();
  // }
  set setup(setup: ISetup) {
    if (setup && setup?.labelItems) {
      setup.labelItems = setup.labelItems.filter(
        (item) => item.fields && item.fields.length > 0
      );
    }
    this._setup = setup;
    this.recalculate();
  }

  @Input()
  set magnification(mag: number) {
    this._magnification = mag;
    this.recalculate();
  }

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Recalculate the style of the label by multiplying each number with the magnification.
   * This is done to zoom in/out the label without changing the actual value of the style properties.
   * The result is stored in the _magnifiedStyle property.
   */
  /******  d923161c-e3c3-4a97-82c6-025999d87855  *******/ recalculate() {
    if (!this._setup) {
      return;
    }
    const resultStyle: any = {};
    Object.keys(this._setup.label).forEach((prop) => {
      if (
        typeof this._setup.label[prop] === 'number' &&
        prop !== 'line-height'
      ) {
        resultStyle[prop] = this._setup.label[prop] * this._magnification;
      } else {
        resultStyle[prop] = this._setup.label[prop];
      }
    });

    if (resultStyle['font-size.pt']) {
      delete resultStyle['font-size.pt'];
    }
    this._magnifiedStyle = resultStyle;
  }

  onItemChange(originalItem: ILabelItem, newItem: ILabelItem) {
    const result: any[] = [];
    const items: TLabelLocation = this.backSide
      ? 'backSideLabelItems'
      : 'labelItems';

    this._setup[items].forEach((item: any) => {
      result.push(item === originalItem ? newItem : item);
    });

    this._setup = {
      ...this._setup,
      [items]: result
    };

    this.setupChange.emit(this._setup);
    this.labelMakerFacade.hasChanges(true);
  }

  updateDimensions(event: Event, target: string, sec: 'page' | 'label') {
    const element = event.target as HTMLInputElement;
    let inputValue = Number(element.value);
    if (target == 'width.mm') {
      inputValue = inputValue;
    } else {
      inputValue = inputValue;
    }
    const value = this.convertInchesToMm(inputValue);
    console.log('this._setup :>> ', this._setup);
    const { width, height } = this.labelService.countMinLabelSize(this._setup);
    if (
      (target === 'height.mm' && value < height) ||
      (target === 'width.mm' && value < width)
    ) {
      element.value = '' + this._setup[sec][target];
      return alert(
        this.translateService.get(
          'Field within the label is blocking the resize!'
        )
      );
    }
    if (value < this.minSize) {
      element.value = '' + this._setup[sec][target];
      return alert(
        this.translateService.get(
          'Cannot make labels smaller than {{size}}mm!',
          { size: this.minSize }
        )
      );
    }

    this._setup = {
      ...this._setup,
      [sec]: {
        ...this._setup[sec],
        [target]: value
      }
    };
    this.setupChange.emit(this._setup);
  }

  setActiveItem(item: ILabelItem) {
    this.activeChange.emit(item);
  }

  fieldUpdate(labelField: any, idx: number = 0) {
    const { itemIdx, location } = this.findItem(labelField);
    if (itemIdx === -1) {
      return;
    }
    this.setupChange.emit({
      ...this._setup,
      [location]: [
        ...this._setup[location].slice(0, itemIdx),
        {
          ...this._setup[location][itemIdx],
          fields: [
            ...this._setup[location][itemIdx].fields.slice(0, idx),
            labelField.fields[0],
            ...this._setup[location][itemIdx].fields.slice(idx + 1)
          ]
        },
        ...this._setup[location].slice(itemIdx + 1)
      ]
    });
    this.labelMakerFacade.hasChanges(true);
  }
  private findItem(item?: ILabelItem): {
    location: TLabelLocation;
    itemIdx: number;
  } {
    const labelItem: any = item;
    console.log(labelItem);
    let location: TLabelLocation = 'labelItems';
    let itemIdx = this._setup.labelItems.findIndex((i: any) => {
      return i.id === labelItem?.id;
    });

    if (itemIdx === -1) {
      location = 'backSideLabelItems';
      itemIdx = (this._setup.backSideLabelItems || []).findIndex(
        (i: any) => i === labelItem
      );
    }
    return { location, itemIdx };
  }

  fieldDelete(field: any, idx: number = 0) {
    const { itemIdx, location } = this.findItem(field);
    if (itemIdx === -1) {
      return;
    }

    this.setupChange.emit({
      ...this._setup,
      [location]: [
        ...this._setup[location].slice(0, itemIdx), // Elements before the index
        ...this._setup[location].slice(itemIdx + 1) // Elements after the index
      ]
    });
    this.labelMakerFacade.hasChanges(true);
    // this.filedDelete.emit(field);
  }
  fieldRemove(idx: number) {}

  updateLabelItemFields(data: any, targetObject: any): any {
    const updatedData = {
      ...data, // Keep the structure intact
      labelItems: data?.labelItems.map((labelItem: any) => ({
        ...labelItem,
        fields: labelItem.fields.map((field: any) =>
          field.label === targetObject.label
            ? { ...field, ...targetObject }
            : field
        )
      }))
    };
    return updatedData;
  }

  convertMmToInches(mm: number): number {
    return parseFloat((mm / 25.4).toFixed(2));
  }

  convertInchesToMm(inches: number): number {
    return parseFloat((inches * 25.4).toFixed(2));
  }
  // Example Usage
}
