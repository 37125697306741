import { LabelDesignerService } from './../../../label-designer.service';
import { Component, inject, Input } from '@angular/core';

@Component({
  selector: 'vex-label-basic-item',
  templateUrl: './label-basic-item.component.html',
  styleUrls: ['./label-basic-item.component.scss']
})
export class LabelBasicItemComponent {
  @Input() item: any;
  @Input() name: string;

  protected labelBuilderService = inject(LabelDesignerService);
  onItemClicked(item: any) {
    this.labelBuilderService.selectItem(item);
  }
}
