<div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "Add email address" }}
    </h2>
  
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button" aria-label="Close">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  
  <mat-divider class="text-border"></mat-divider>
  
 <mat-dialog-content>
     <mat-form-field class="w-full">
        <mat-label>To Email Address</mat-label>
        <mat-chip-grid #toChipGrid aria-label="Enter emails">
          <mat-chip-row *ngFor="let email of toEmails"
                        (removed)="remove(email,'to')"
                        [editable]="true"
                        (edited)="edit(email, $event,'to')"
                        [aria-description]="'press enter to edit ' + email.address">
            {{email.address}}
            <button matChipRemove [attr.aria-label]="'remove ' + email.address" aria-label="cancel">
              <mat-icon svgIcon="mat:cancel" class="text-[red]"></mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="New email..."
                 [formControl]="toEmailFormControl"
                 [matChipInputFor]="toChipGrid"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event,'to')" />
        </mat-chip-grid>
      
        <mat-error *ngIf="toEmailFormControl.hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
      <!-- <span *ngIf="ccEmailFormControlError">
        Please enter a valid email address
      </span> -->

      <mat-form-field class="w-full">
        <mat-label>Carbon Copy (CC) Email Address</mat-label>
        <mat-chip-grid #ccChipGrid aria-label="Enter Carbon Copy (CC)  emails"   [formControl]="ccEmailFormControl">
          <mat-chip-row *ngFor="let email of ccEmails"
                        (removed)="remove(email,'cc')"
                        [editable]="true"
                        (edited)="edit(email, $event,'cc')"
                        [aria-description]="'press enter to edit ' + email.cc">
            {{email.cc}}
            <button matChipRemove [attr.aria-label]="'remove ' + email.cc">
              <mat-icon svgIcon="mat:cancel" class="text-[red]"></mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="New Carbon Copy (CC)  email..."
               
                 [matChipInputFor]="ccChipGrid"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event,'cc')" />
        </mat-chip-grid>
      
 <mat-error *ngIf="ccEmailFormControl.hasError('required') && ccEmailFormControl.touched">
  Email is required
</mat-error>
      </mat-form-field>


      <mat-form-field class="w-full">
        <mat-label>Blind Carbon Copy(BCC) Email Address</mat-label>
        <mat-chip-grid #bccChipGrid aria-label="Enter Blind Carbon Copy (BCC) emails"   [formControl]="bccEmailFormControl">
          <mat-chip-row *ngFor="let email of bccEmails"
                        (removed)="remove(email,'bcc')"
                        [editable]="true"
                        (edited)="edit(email, $event,'bcc')"
                        [aria-description]="'press enter to edit ' + email.bcc">
            {{email.bcc}}
            <button matChipRemove [attr.aria-label]="'remove ' + email.bcc">
              <mat-icon svgIcon="mat:cancel" class="text-[red]"></mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="New Blind Carbon Copy (BCC)  email..."
               
                 [matChipInputFor]="bccChipGrid"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event,'bcc')" />
        </mat-chip-grid>
      
 <mat-error *ngIf="bccEmailFormControl.hasError('required') && bccEmailFormControl.touched">
  Email is required
</mat-error>
      </mat-form-field>
      <!-- <span *ngIf="ccEmailFormControlError">
        Please enter a valid email address
      </span> -->
      
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">

    <button
      color="primary"
      mat-flat-button
      type="submit"
      (click)="onSubmit()"
      (keyup.enter)="onSubmit()"
      >
      Submit
    </button>
  </mat-dialog-actions>
  