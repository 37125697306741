<ll-available-fields
  [availableFields]="addableFields"
  (valueChange)="addNewField($event)"></ll-available-fields>
<ll-field-value-map
  *ngFor="let field of mapped"
  [title]="fieldLookup[field].label"
  [mapping]="valueMap[field]"
  (mappingChange)="updateFieldMap(field, $event)"
  (remove)="removeFromMap(field)"
  (refresh)="addMappingByField(field)"></ll-field-value-map>
