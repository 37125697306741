// auth.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AppSettingsService } from '../app-settings.service';
import { UserAuthenticationService } from '../navigation/user-authentication.service';
import { PermissionGuard } from './permission.guard';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdmin implements CanActivate {
  constructor(
    private router: Router,
    private appSettingsService: AppSettingsService,
    public userCheck: UserAuthenticationService,
    private permissionGuard: PermissionGuard
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.userCheck.checkUser()) {
      const currentUrl = this.router.url;
      const menuName = currentUrl.split('/').pop() || ''; // Gets the last part of the URL

      const skipURLs = ['login', 'register', '', '/']; // Add any routes that don’t require permission check
      // if(!skipURLs.includes(menuName) && !skipURLs.some(url => currentUrl.includes(url))){
      //   this.permissionGuard.checkPermission(menuName);
      // }

      return true;
    } else {
      const userRole = localStorage.getItem('role');
      // Redirect to sign-in page if not authenticated
      this.router.navigate(['/login']);
      return false;
    }
  }

  private isUserAuthenticated(): boolean {
    const userRole = localStorage.getItem('role');
    if (!userRole) {
      return false;
    }
    return true;
  }
}
