import { Component, Input, Signal, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleFadeIn1000ms } from '@vex/animations/scale-fade-in.animation';
import { MatTooltipModule } from '@angular/material/tooltip';

// Define an interface named 'Image'
export interface Image {
  url: string; // Represents the URL of the image
  width?: string | '200px'; // Represents the width of the image. It is optional and defaults to '200px' if not provided
  height?: string | 'auto'; // Represents the height of the image. It is optional and defaults to 'auto' if not provided
  alt?: string; // Represents the alternative text for the image
  title?: string; // Represents the title of the image
  error?: boolean; // Represents a flag indicating whether an error occurred while loading the image
}

@Component({
  selector: 'image-gallery-merfor',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './image-gallery-merfor.component.html',
  styleUrls: ['./image-gallery-merfor.component.scss'],
  animations: [scaleFadeIn1000ms]
})
export class ImageGalleryMerfor {
  // Define an input property 'images' of type 'Image[]' with a default value of an empty array
  @Input() images: Image[] = [];
  @Input() imagesUrl: any;
  @Input() altImage: string = 'image';
  @Input() style: any = { width: '200px', height: '300px' };
  // Define a boolean property 'showImage' and initialize it to false
  showImage: boolean = false;
  showImageSingle: boolean = false;
  // Define a property 'image' of type 'Image'
  image: Image;

  // Define a method 'toggleFullScreen' that takes an 'img' parameter of type 'Image'
  toggleFullScreen(img: Image): void {
    // Set 'showImage' to true to indicate that the image should be displayed in full screen
    this.showImage = true;
    // Set 'image' to the selected image
    this.image = img;
  }

  imageSingle: string;
  toggleFullScreenSingle(img: any): void {
    // Set 'showImage' to true to indicate that the image should be displayed in full screen
    this.showImageSingle = true;
    // Set 'image' to the selected image
    this.imageSingle = img;
  }

  // Define a method 'onImageError' that takes 'event' and 'image' parameters of type 'any' and 'Image' respectively
  onImageError(event: any, image: Image): void {
    // Set the 'error' property of the 'image' object to true to indicate that an error occurred while loading the image
    image.error = true;
  }

  defaultUserImage: string = 'assets/img/logo/error-loading.svg';
  handleImageError(event: any) {
    // Set the source of the image to the default image path
    event.target.src = this.defaultUserImage;
  }
}
