<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ title() }}
  </h2>

  <button
    class="text-secondary"
    mat-dialog-close
    mat-icon-button
    type="button"
    aria-label="Close dialog">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
  <ng-container *ngTemplateOutlet="img"> </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    color="primary"
    mat-flat-button
    type="submit"
    (click)="onSubmit()"
    aria-label="Submit">
    Submit
  </button>
</mat-dialog-actions>

<ng-template #img>
  <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
    <div
      *ngFor="let image of imageData"
      class="relative bg-[#f4f4f4] rounded-sm py-2"
      [ngClass]="{
        '!border-2 !border-[#17aeff]': isImageSelected(image),
        '!border !border-[#ccc]': !isImageSelected(image)
      }"
      (click)="onSelectImage(image)">
      <image-gallery-merfor
        [imagesUrl]="image?.url"
        [altImage]="image?.name"
        class="object-scale-down w-full h-34 rounded-sm">
      </image-gallery-merfor>
    </div>
  </div>
</ng-template>
