import { initialDataResolver } from './app.resolvers';
import { AuthGuardAdmin } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { AuthGuardSubAdmin } from './core/guards/subadmin.auth.guard';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./pages/auth/login/login.component').then(
            (m) => m.LoginComponent
          )
      },
      {
        path: 'register',
        loadComponent: () =>
          import('./pages/auth/register/register.component').then(
            (m) => m.RegisterComponent
          )
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./pages/auth/forgot-password/forgot-password.component').then(
            (m) => m.ForgotPasswordComponent
          )
      },
      {
        path: 'reset-password/:token',
        loadComponent: () =>
          import('./pages/auth/reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent
          )
      },
      {
        path: 'new-password/:token',
        loadComponent: () =>
          import('./pages/auth/new-password/new-password.component').then(
            (m) => m.NewPasswordComponent
          )
      },
      {
        path: 'verify_email_instructions',
        loadComponent: () =>
          import(
            './pages/auth/register/verify-email-instructions/verify-email-instructions.component'
          ).then((m) => m.VerifyEmailInstructionsComponent)
      },
      {
        path: 'verify-email/:token',
        loadComponent: () =>
          import('./pages/auth/verify-email/verify-email.component').then(
            (m) => m.VerifyEmailComponent
          )
      },
      {
        path: 'approval_email_instruction',
        loadComponent: () =>
          import('./pages/auth/approval-email/approval-email.component').then(
            (m) => m.ApprovalEmailComponent
          )
      },
      {
        path: 'coming-soon',
        loadComponent: () =>
          import('./pages/coming-soon/coming-soon.component').then(
            (m) => m.ComingSoonComponent
          )
      }
    ]
  },
  //No auth Routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [NoAuthGuard],
    resolve: {
      initialData: initialDataResolver
    },
    children: [
      { path: 'home', loadChildren: () => import('./pages/home/home.routes') },

      {
        path: 'about',
        loadComponent: () =>
          import('./pages/about/about.component').then((m) => m.AboutComponent)
      },
      {
        path: 'contact',
        loadComponent: () =>
          import('./pages/contact/contact.component').then(
            (m) => m.ContactComponent
          )
      }
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        resolve: {
          initialData: initialDataResolver
        },
        children: [
          {
            path: 'social',
            loadChildren: () => import('./pages/social/social.routes')
          },
          {
            path: 'program',
            loadChildren: () => import('./pages/program/program-child.routes')
          }
        ]
      }
    ]
  },
  //admin and

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuardAdmin],
        resolve: {
          initialData: initialDataResolver
        },
        children: [
          {
            path: 'dashboard',
            loadComponent: () =>
              import('./pages/cqa-dashboard/cqa-dashboard.component').then(
                (m) => m.CQADashboardComponent
              ),
            data: {
              toolbarShadowEnabled: true
            }
          },

          {
            path: 'formulator',
            loadChildren: () => import('./pages/licensee/licensee.routes')
          },
          {
            path: 'licensee',
            loadChildren: () => import('./pages/licensee/licensee.routes')
          },
          {
            path: 'company',
            loadChildren: () => import('./pages/licensee/licensee.routes')
          },

          {
            path: 'company_completed_forms_documents',
            loadComponent: () =>
              import(
                './pages/social/company-profile/component/completed-files-list/completed-files-list.component'
              ).then((m) => m.CompletedFilesComponent),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/users/users.routes')
          },
          {
            path: 'programs',
            loadChildren: () => import('./pages/program/program.routes')
          },
          {
            path: 'master-data',
            loadChildren: () => import('./pages/master-data/master-data.routes')
          },
          {
            path: 'sample',
            loadChildren: () => import('./pages/sample/sample.routes')
          },
          {
            path: 'payments',
            loadChildren: () => import('./pages/payments/payments.routes')
          },
          {
            path: 'reports',
            loadChildren: () => import('./pages/reports/reports.routes')
          },
          {
            path: 'brand',
            loadChildren: () =>
              import(
                './pages/program/program/components/program-branding/program-branding.routes'
              )
          },
          {
            path: 'quotations',
            loadComponent: () =>
              import(
                './pages/quotation/quotation-list/quotation-list.component'
              ).then((m) => m.QuotationListComponent),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'list-configuration',
            loadChildren: () =>
              import('./pages/list-configuration/list-configuration.routes')
          },
          {
            path: 'action-items',
            loadComponent: () =>
              import(
                './pages/action-item/action-item-list/action-item-list.component'
              ).then((m) => m.ActionItemListComponent)
          },
          {
            path: 'invoices',
            loadComponent: () =>
              import(
                './pages/payments/components/invoices/invoice-list/invoice-list.component'
              ).then((m) => m.InvoiceListComponent)
          },
          {
            path: 'product-label-list',
            loadComponent: () =>
              import(
                './pages/program/program/components/program-branding/component/product-label-list/product-label-list.component'
              ).then((m) => m.ProductLabelListComponent)
          },
          {
            path: 'brandNames',
            loadComponent: () =>
              import(
                './pages/program/program/components/program-branding/program-branding.component'
              ).then((m) => m.ProgramBrandingComponent)
          },
          {
            path: 'WebsiteBrandList',
            loadComponent: () =>
              import(
                './pages/program/program/components/program-branding/program-branding.component'
              ).then((m) => m.ProgramBrandingComponent)
          },
          {
            path: 'license-agreement',
            loadComponent: () =>
              import(
                './pages/licensee/components/license-agreement/license-agreement-list/license-agreement-list.component'
              ).then((m) => m.LicenseAgreementListComponent)
          },
          {
            path: 'formulation',
            loadChildren: () => import('./pages/sample/sample.routes')
          },
          {
            path: 'audit',
            loadChildren: () => import('./pages/sample/audit.routes')
          },
          {
            path: 'payments',
            loadChildren: () => import('./pages/payments/payments.routes')
          },
          {
            path: 'taf',
            loadComponent: () =>
              import('./pages/TAF/taf-list/taf-list.component').then(
                (m) => m.TafListComponent
              )
          },

          {
            path: 'renewalNotices',
            loadComponent: () =>
              import(
                './pages/renewal-notices/renewal-notices-list/renewal-notices-list.component'
              ).then((m) => m.RenewalNoticesListComponent)
          },

          {
            path: 'expiration_termination_notices',
            loadComponent: () =>
              import(
                './pages/expiration-termination-notices/expiration-termination-notices-list/expiration-termination-notices-list.component'
              ).then((m) => m.ExpirationTerminationNoticesListComponent)
          }
        ]
      }
    ]
  },
  //subadmin routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardSubAdmin],
    resolve: {
      initialData: initialDataResolver
    },
    children: [
      {
        path: 'checkout',
        loadComponent: () =>
          import('./pages/shared/checkout/checkout.component').then(
            (m) => m.checkoutComponent
          )
      },
      {
        path: 'payment_success',
        loadComponent: () =>
          import(
            './pages/components/payment-success/payment-success.component'
          ).then((m) => m.PaymentSuccessComponent)
      },
      {
        path: 'payment_failed',
        loadComponent: () =>
          import(
            './pages/components/payment-failed/payment-failed.component'
          ).then((m) => m.PaymentFailedComponent)
      }
    ]
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./pages/errors/access-denied/access-denied.component').then(
        (m) => m.AccessDeniedComponent
      )
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/errors/error-404/error-404.component').then(
        (m) => m.Error404Component
      )
  }
];
