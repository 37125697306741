<div
  *ngIf="userInfo?.accessToken"
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple>
  <div
    class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
    {{ userInfo?.userDetails.firstName + " " + userInfo.userDetails.lastName }}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10">
    <img
      *ngIf="userprofileURL; else defaultIcon"
      class="object-cover rounded-full h-9 w-9"
      [src]="userprofileURL"
      loading="lazy"
      (error)="handleImageError($event)"
      alt="Profile Picture" />
    <ng-template #defaultIcon>
      <mat-icon svgIcon="mat:person"></mat-icon>
    </ng-template>
  </div>
</div>

<div
  *ngIf="!userInfo?.accessToken"
  class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
  (click)="navigate()"
  matRipple>
  <div
    class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
    Sign in / Register
  </div>
</div>
