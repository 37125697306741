<table class="table-auto w-full my-3">
  <tr *ngIf="item.class == 'sample'">
    <td class="p-4" colspan="2" *ngIf="showCheckBox">
      <mat-checkbox
        color="primary"
        [(ngModel)]="isChecked"
        (change)="onCheckChange()">
      </mat-checkbox>
    </td>
    <td class="p-2 align-top">
      <h4 class="font-base text-base">
        {{ item?.sampleType?.name }}
      </h4>
      <p class="text-xs text-gray-400">
        Company Sample ID: {{ item?.sampleDetails?.CoSampleID || "-" }}<br />
        CQA Sample ID: {{ item?.sampleDetails?.CQAId || "-" }}
        <br />
      </p>
      <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
        PO Number:{{ PONumber ? PONumber : "-" }}
        <br />
      </p>
    </td>
    <td class="p-2 align-top">
      <div class="price flex flex-col justify-end items-end gap-3">
        <h6 class="text-base">
          {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
        </h6>
        <mat-icon
          *ngIf="showDelete"
          class="cursor-pointer text-gray-400"
          (click)="deleteCart(item)"
          svgIcon="mat:clear"
          matTooltip="Remove item"></mat-icon>
      </div>
    </td>
  </tr>

  <tr *ngIf="item.class == 'brand'">
    <td class="p-4">
      <h4 class="font-base text-base">{{ "Brands" }}</h4>
    </td>
  </tr>

  <tr *ngIf="item.class == 'LicenseAgreement'">
    <td class="p-2 align-top">
      <div class="description flex flex-col justify-start items-start gap-3">
        <h4 class="text-base text-start">{{ item?.name }}</h4>

        <h4 class="text-base text-start" *ngIf="item.isDueAgreement">
          Late Payment Finance Charge
        </h4>
        <p class="text-sm text-start text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
      </div>
    </td>
    <td class="p-2 align-top">
      <div class="price flex flex-col justify-end items-end gap-3">
        <h6 class="text-base">
          {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
        </h6>
        <h5 class="text-base" *ngIf="item.isDueAgreement">
          {{ item.latePaymentFee | currency: "USD" : "symbol" : "1.0-0" }}
        </h5>
        <div *ngIf="showPO"></div>
      </div>
    </td>
  </tr>
</table>
