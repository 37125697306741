<!-- <ng-container *ngIf="_field.type === fieldType.qrCode; else plainText">
  <qrcode
    [qrdata]="_field.content"
    [size]="size"
    [level]="qrCodeErrorCorrectionLevel"
    [usesvg]="true"></qrcode>
</ng-container> -->

<!-- <ng-container *ngIf="_field.type === fieldType.barcode">
  <ngx-barcode
    [bc-element-type]="img"
    [bc-width]="1.2"
    [bc-height]="70"
    [bc-value]="_field.content"></ngx-barcode>
</ng-container> -->

<!-- <ng-template #plainText> -->
<!-- <span
        *ngIf="_field.prefix && (_field.content | hasValue)"
        [innerHTML]="_field.prefix"
        [ngStyle]="_prefixStyle"
    ></span> -->
<!-- <span
        *ngIf="_field.field_type == 'title'"
        [innerHTML]="_field.label"
        [ngStyle]="_prefixStyle"
    ></span> -->
<span
  [ngStyle]="_contentStyle"
  *ngIf="
    _field.type != 'date' &&
    _field.field_type != 'title' &&
    _field.text_type != 'message' &&
    _field.type != 'img'
  "
  [innerHtml]="_field.content"></span>
<span
  *ngIf="
    _field.type != 'date' &&
    _field.field_type != 'title' &&
    _field.text_type == 'message' &&
    _field.type != 'img'
  "
  [ngStyle]="_contentStyle"
  style="text-wrap: auto; line-height: normal"
  [innerHtml]="_field.content"></span>
<span
  *ngIf="_field.suffix && (_field.content | hasValue)"
  [ngStyle]="_suffixStyle"
  [innerHTML]="_field.suffix"></span>
<span
  *ngIf="((_field.content | hasValue) && !isLast) || _field.separatorAlways"
  [innerHTML]="_field.separator | separator"></span>
<span *ngIf="_field.type == 'date'" [innerHtml]="_field.content"></span>

<img
  *ngIf="_field.type == 'img'"
  [src]="_field.content"
  alt="image"
  [ngStyle]="_contentStyle" />
<!-- </ng-template> -->
