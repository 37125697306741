import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NetworkWarningComponent } from './core/shared/network-warning/network-warning.component';
@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NetworkWarningComponent]
})
export class AppComponent implements OnInit {
  ngOnInit() {
    // PullToRefresh.init({
    //   mainElement: 'body', // The area where pull-to-refresh should work
    //   triggerElement: 'body', // The element that triggers the pull-to-refresh action
    //   onRefresh() {
    //     // Action to perform on pull-to-refresh (e.g., API call or page reload)
    //     if (window.scrollY === 0) {
    //       window.location.reload();
    //     }
    //   },
    //   shouldPullToRefresh() {
    //     // Only allow pull-to-refresh when the user is at the top of the page
    //     return window.scrollY === 0;
    //   },
    //   distThreshold: 60, // Minimum distance required to trigger a refresh
    //   distMax: 100, // Maximum distance the user can pull
    //   distReload: 50, // Distance required for the refresh action to occur
    //   resistanceFunction(t) {
    //     // Resistance logic, this slows down the pull effect
    //     return Math.min(1, t / 2.5);
    //   },
    //   refreshTimeout: 300 // Slight delay for the refresh action
    // });
  }
}
