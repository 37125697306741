import { LabelPrinterService } from './label.printer.service';
import { LabelCommandService } from './label-command.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { map, Observable } from 'rxjs';
import moment from 'moment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class FQLabelBuilderService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
    private labelCommandService: LabelCommandService,
    private labelPrinterService: LabelPrinterService
  ) {}

  availableFields = [
    {
      field: 'product.name', // Values is picked from this key on the data
      content: 'Unisex Tshirt',
      label: 'Product Name',
      type: 'text', // Tells the Label Designer to convert this field value to QR Code
      prefix: '<b>Product Name: &nbsp;</b>'
    },
    {
      field: 'product_variant.title',
      content: 'XL', // This value is shown on the preview
      label: 'Variant Name', // This value is shown on the editor
      type: 'text',
      prefix: '<b>Variant Name: &nbsp;</b>'
    },
    {
      field: 'product_variant.upc',
      content: '40', // This value is shown on the preview
      label: 'UPC', // This value is shown on the editor
      type: 'text',
      prefix: '<b>UPC: &nbsp;</b>'
    },
    {
      field: 'product_variant.thc',
      content: '30.5', // This value is shown on the preview
      label: 'THC', // This value is shown on the editor
      type: 'text',
      prefix: '<b>THC: &nbsp;</b>'
    },
    {
      field: 'thca',
      content: '30.5', // This value is shown on the preview
      label: 'THCA', // This value is shown on the editor
      type: 'text',
      prefix: '<b>THCA: &nbsp;</b>'
    },
    {
      field: 'product_variants.sku',
      content: '', // This value is shown on the preview
      label: 'SKU', // This value is shown on the editor
      type: 'text',
      prefix: '<b>SKU: &nbsp;</b>'
    },
    {
      field: 'product_variant.cbd',
      content: '20.5', // This value is shown on the preview
      label: 'CBD', // This value is shown on the editor
      type: 'text',
      prefix: '<b>CBD: &nbsp;</b>'
    },
    {
      field: 'product_variant.genetics',
      content: 'Indica', // This value is shown on the preview
      label: 'Genetics', // This value is shown on the editor
      type: 'text',
      prefix: '<b>Genetics: &nbsp;</b>'
    },
    {
      field: 'product_variant.cbd',
      content: '20.5', // This value is shown on the preview
      label: 'Packaged At', // This value is shown on the editor
      type: 'text',
      prefix: '<b>Packaged At: &nbsp;</b>'
    },
    {
      field: 'facility_license_number',
      content: '20.5', // This value is shown on the preview
      label: 'Metrc Facility', // This value is shown on the editor
      type: 'text',
      prefix: '<b>Metrc Facility: &nbsp;</b>'
    },
    {
      field: 'text', // This fields value is not picked from the data
      content: 'Text',
      label: 'TEXT',
      type: 'text', // text type means that the value can be inputted from editor and it's not picked from the data.
      field_type: 'dynamic'
    },
    {
      field: 'barcode', // This fields value is not picked from the data
      content: '1A4FF0100000522000000007',
      label: 'BARCODE',
      type: 'barcode' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'quantity', // This fields value is not picked from the data
      content: '12',
      label: 'Quantity',
      type: 'text' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'uom.abbreviation', // This fields value is not picked from the data
      content: 'g',
      label: 'UOM',
      type: 'text' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'package_date', // This fields value is not picked from the data
      content: '05/05/2024',
      label: 'Package Date',
      type: 'date',
      prefix: '<b>Package Date: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'cultivator_name', // This fields value is not picked from the data
      content: 'Name of Cultivator/Producer',
      label: 'Cultivator/Producer',
      type: 'text',
      prefix: '<b>Name of Cultivator/Producer: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'cultivator_lic_no', // This fields value is not picked from the data
      content: 'Name of Cultivator/Producer',
      label: 'Cultivator/Producer License',
      type: 'text',
      prefix: '<b>Name of Cultivator/Producer: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'packaged_by_lic_no', // This fields value is not picked from the data
      content: 'AU-MB-000001',
      label: 'Packaged by License',
      type: 'text',
      prefix: '<b>License: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'id', // This fields value is not picked from the data
      content: '1A4FF0100000522000000007',
      label: 'Package ID',
      type: 'text',
      prefix: '<b>Package Id: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'packaged_by_name', // This fields value is not picked from the data
      content: 'MI AU Microbusiness 1',
      label: 'Packaged by Name',
      type: 'text',
      prefix: '<b>Package Date: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'date_of_harvest', // This fields value is not picked from the data
      content: '05/05/2024',
      label: 'Date of Harvest',
      type: 'date',
      prefix: '<b>Date of Harvest: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'strain.Name', // This fields value is not picked from the data
      content: 'Strain name',
      label: 'Metrc Strain',
      type: 'text',
      prefix: '<b>Strain: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'net_weight', // This fields value is not picked from the data
      content: '1g (3.05z)',
      label: 'Net Weight',
      type: 'text',
      prefix: '<b>Net Weight: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'activation_time', // This fields value is not picked from the data
      content: 'Immidiate',
      label: 'Activation time',
      type: 'text',
      prefix: '<b>Activation time: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'testing_lab_name', // This fields value is not picked from the data
      content: 'Marijuna dipsen,California',
      label: 'Testing Lab',
      type: 'text',
      prefix: '<b>Testing Lab Name: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'testing_sample', // This fields value is not picked from the data
      content: '1A4FF0100000522000000007',
      label: 'Testing Sample Pkg ID',
      type: 'text',
      prefix: '<b>Testing Sample Pkg ID: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'lab_test_date', // This fields value is not picked from the data
      content: '05/05/2024Testing Lab License',
      label: 'Lab Test Date',
      type: 'date',
      prefix: '<b>Lab Test Date: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },
    {
      field: 'testing_lab_lic_no', // This fields value is not picked from the data
      content: 'MI micorbusiness',
      label: 'Testing Lab License',
      type: 'text',
      prefix: '<b>Testing Lab License: &nbsp;</b>' // text type means that the value can be inputted from editor and it's not picked from the data.
    },

    {
      field: 'text',
      content:
        '**Warnings often already on product but may need to be added in special occasions** ',
      label: 'Custom Text 1',
      type: 'text'
    },
    {
      field: 'text',
      content:
        'It is illegal to drive a motor vehicle while under the influence of marihuana',
      label: 'Custom Text 2',
      type: 'text'
    },
    {
      field: 'text',
      content: 'National Poison Control Center 1-800-222-1222',
      label: 'Custom Text 3',
      type: 'text'
    },
    {
      field: 'text',
      content:
        'For use by registered qualifying patients only. Keep out of reach of children. **Medical Only**',
      label: 'Custom Text 4',
      type: 'text'
    },
    {
      field: 'text',
      content:
        'For use by individuals 21 years of age or older or registered qualifying patients only. Keep out of reach of children.” **Adult Use Only** ',
      label: 'Custom Text 5',
      type: 'text'
    },
    {
      field: 'text',
      content:
        'WARNING; USE BY PREGNANT OR BREASTFEEDING WOMEN, OR BY WOMEN PLANNING TO BECOME PREGNANT, MAY RESULT IN FETAL INJURY, PRETERM BIRTH, LOW BIRTH WEIGHT, OR DEVELOPMENTAL PROBLEMS FOR THE CHILD ',
      label: 'Custom Text 6',
      type: 'text'
    },
    {
      field: 'warning_img',
      label: 'Marijuna Product',
      type: 'img'
    }
  ];

  replacePlaceholders(zplCommand: string): string {
    // Loop through all fields in the availableFields array
    const fields = this.availableFields;
    fields.forEach((field) => {
      // Check if the field has a valid key and content
      if (field.field && field.content !== undefined) {
        const placeholder = `{{${field.field}}}`; // Create the placeholder string
        zplCommand = zplCommand.replace(
          new RegExp(placeholder, 'g'),
          field.content
        ); // Replace all occurrences
      }
    });
    return zplCommand;
  }

  public printLabelForPreview({
    data,
    config,
    registerId
  }: {
    data: any;
    config: any;
    registerId: string;
  }) {
    //TODO: send these values from settings
    // Manually set the values for now
    const resolution = '203';
    const orientation = 'landscape';
    const language = 'zpl';
    const labelData = JSON.parse(data.config);
    const labelSettings = labelData.label;
    // const size_in_inches = '2x1'; // width and height should be a string seperated by x
    const size_in_inches = `${this.convertMmToInches(
      labelSettings['width.mm']
    )}x${this.convertMmToInches(labelSettings['height.mm'])}`; // width and height getting from label

    const [labelWidthInches, labelHeightInches] = size_in_inches
      .split('x')
      .map(Number);
    const commandWithPlaceHolder =
      this.labelCommandService.buildLabelFromConfig({
        dpi: parseInt(resolution), // Extract dpi number
        labelWidthInches, // Width extracted from the string
        labelHeightInches, // Height extracted from the string
        orientation: orientation, // Adjust for landscapes
        language: language.toLowerCase() as 'zpl' | 'ipl',
        config: data.config, // Assuming config is already a JSON string representing the label structure
        format: true
      });
    const command = this.replacePlaceholders(commandWithPlaceHolder);
    console.log('command', command);
    // this.labelPrinterService.printPreviewLabel({
    //   data: command,
    //   config,
    //   registerId
    // });
  }

  public createLabel(body: any) {
    //TODO: create input fields for these to set from UI
    // Manually set the values for now
    const resolution = '203';
    const orientation = 'landscape';
    const language = 'zpl';

    const labelData = JSON.parse(body.config);
    const labelSettings = labelData.label;
    // const size_in_inches = '2x1'; // width and height should be a string seperated by x
    const size_in_inches = `${this.convertMmToInches(
      labelSettings['width.mm']
    )}x${this.convertMmToInches(labelSettings['height.mm'])}`; // width and height getting from label

    // Convert size_in_inches to width and height by splitting the string "widthxheight"
    const [labelWidthInches, labelHeightInches] = size_in_inches
      .split('x')
      .map(Number);

    // Generate the label command based on the manually provided values and the body config
    const command = this.labelCommandService.buildLabelFromConfig({
      dpi: parseInt(resolution), // Extract dpi number
      labelWidthInches, // Width extracted from the string
      labelHeightInches, // Height extracted from the string
      orientation: orientation, // Adjust for landscapes
      language: language.toLowerCase() as 'zpl' | 'ipl',
      config: body.config, // Assuming config is already a JSON string representing the label structure
      format: false
    });

    // Update the body with the generated command
    const updatedBody = {
      ...body,
      resolution,
      orientation,
      language,
      size_in_inches,
      command // Include the generated command in the API request body
    };

    // Make the API call
    return this.http.post(`label-config`, updatedBody).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  public getAllLabels(id: any, state: any) {
    return this.http
      .get(`label-config?sort=!id&dispensary_id=$in0|${id}&state=${state}`)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }
  public getLabels(queryParams: any): Observable<any> {
    const params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get(`label-config?` + params, httpOptions).pipe(
      map((resp: any) => {
        const retData: any = {
          items: [],
          totalCount: '',
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          const rows = resp.data.rows;
          let index = 1;

          for (const item of rows) {
            const temp: LabelInterface = {
              indexNo: index++,
              id: item.id,
              config: item.config,
              name: item.name,
              createdAt: moment(item.createdAt).format('M/D/YY, h:mm a'),
              updatedAt: item.updatedAt,
              dispensary_id: item.dispensary_id
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }
        return retData;
      })
    );
  }
  // public getPreview(inventaryId, labelId) {
  //     return this.http.get(`label_preview/${inventaryId}/${labelId}`).pipe(
  //         map((resp) => {
  //             return parseResponse(resp);
  //         })
  //     );
  // }
  public getPreview(inventoryId: any, labelId: any) {
    return this.http.get(`label_preview/${inventoryId}/${labelId}`).pipe(
      map((resp: any) => {
        if (resp && resp?.data?.labelItems?.length > 0) {
          resp.data.labelItems = resp?.data?.labelItems.map((item?: any) => {
            if (item && item?.fields && item?.fields?.length > 0) {
              item.fields = item?.fields.map((field?: any) => {
                if (field?.field) {
                  const content = field?.content;

                  // Check if the content is a valid date and format it
                  if (content) {
                    if (
                      field.field === 'package_date' ||
                      field.field === 'date_of_harvest' ||
                      field.field === 'lab_test_date'
                    ) {
                      const formattedDate = moment(content).isValid()
                        ? moment(content).format('MM/DD/YYYY')
                        : 'N/A';
                      field.content = formattedDate;
                    }
                  }
                  // if (field?.field == 'product.name') {
                  //     field.label = 'Product';
                  // }
                  // if (
                  //     field?.field == 'product_variant.title'
                  // ) {
                  //     field.label = 'Variant';
                  // }
                  // if (
                  //     field?.field == 'product_variant.title'
                  // ) {
                  //     field.label = 'Variant';
                  // }
                }

                return field; // Ensure the modified field is returned
              });
            }
            return item; // Ensure the modified item is returned
          });
        }
        return parseResponse(resp);
      })
    );
  }

  // Update the label
  public updateLabel(labelId: string, params: any): Observable<any> {
    const resolution = '203';
    const orientation = 'landscape';
    const language = 'zpl';
    // Assuming you have the ZPL/label generation service injected

    const labelConfig = params.config; // Extract the label configuration from params
    const labelData = JSON.parse(params.config);
    const labelSettings = labelData.label;
    // const size_in_inches = '2x1'; // width and height should be a string seperated by x
    const size_in_inches = `${this.convertMmToInches(
      labelSettings['width.mm']
    )}x${this.convertMmToInches(labelSettings['height.mm'])}`; // width and height getting from label
    const [labelWidthInches, labelHeightInches] = size_in_inches
      .split('x')
      .map(Number);

    // Generate the ZPL command based on the label configuration
    const zplCommand = this.labelCommandService.buildLabelFromConfig({
      dpi: parseInt(resolution),
      labelWidthInches: labelWidthInches,
      labelHeightInches: labelHeightInches,
      orientation: orientation,
      language: language,
      config: labelConfig,
      format: false
    });

    // Include the generated ZPL command in the params
    params.command = zplCommand;

    return this.http.put('label-config/' + labelId, params, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  /**
   * deleteLabel
   */
  public deleteLabel(labelId: string) {
    return this.http.delete('label-config/' + labelId, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  public getOrderLabelPreview(orderId: any, labelId: any) {
    return this.http.get(`order_label_preview/${orderId}/${labelId}`).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  private convertMmToInches(mm: number): number {
    return parseFloat((mm / 25.4).toFixed(2));
  }
}
export interface LabelInterface {
  id: number;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  config?: any;
  dispensary_id?: number;
  resolution?: string;
  orientation?: string;
  command?: string;
  language?: string;
  size_in_inches?: string;
  indexNo?: number;
}
