<svg [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'logo'">
            <g>
                <rect x="2" y="2" width="20" height="20" ry="3" fill="none" stroke="#333" stroke-width="1" />
            </g>
            <path
                d="m14.127 7.1925 6.1-5.5529c0.4848-0.44132 1.2304-0.40634 1.6717 0.078485l0.55303 0.60752c0.44132 0.4848 0.40632 1.2304-0.07848 1.6717l-6.1 5.5529c-0.48482 0.44134-4.2194 1.8344-4.0268 1.2077l0.0078-0.02786c0.19264-0.62668 1.3871-3.0963 1.872-3.5377z"
                fill="none"
                stroke="#333"
                stroke-width="1"
            />
            <g transform="scale(3) translate(.5, .5)">
                <rect
                    x="1.034"
                    y="1.057"
                    width=".566"
                    height=".58"
                    ry="0"
                    fill="#fff"
                    stroke="#333"
                    stroke-width=".136"
                />
                <rect
                    x="2.636"
                    y="1.057"
                    width=".566"
                    height=".58"
                    ry="0"
                    fill="#fff"
                    stroke="#333"
                    stroke-width=".136"
                />
                <rect
                    x="1.034"
                    y="2.481"
                    width=".566"
                    height=".58"
                    ry="0"
                    fill="#fff"
                    stroke="#333"
                    stroke-width=".136"
                />
                <rect x="1.174" y="1.2" width=".286" height=".292" ry="0" />
                <rect x="2.776" y="1.2" width=".286" height=".292" ry="0" />
                <rect x="1.174" y="2.625" width=".286" height=".292" ry="0" />
                <rect x="2.161" y="1.03" width=".320" height=".302" ry="0" />
                <rect x="1.805" y="1.327" width=".676" height=".288" ry="0" />
                <rect transform="rotate(90)" x="2.476" y="-2.158" width=".676" height=".288" ry="0" />
                <rect x=".9636" y="1.767" width=".301" height=".296" ry="0" />
                <rect x="2.448" y="1.749" width=".301" height=".302" ry="0" />
                <rect x="1.804" y="1.749" width=".662" height=".293" ry="0" />
                <rect x="1.236" y="2.037" width=".595" height=".334" ry="0" />
                <rect x="2.160" y="2.042" width=".301" height=".302" ry="0" />
                <rect x="2.416" y="2.782" width=".301" height=".354" ry="0" />
                <rect x="2.151" y="2.480" width=".301" height=".302" ry="0" />
                <rect x="2.73" y="2.051" width=".301" height=".354" ry="0" />
            </g>
            <g transform="translate(4, 15) scale(0.18)">
                <text x="0" y="0">Parus major</text>
                <text x="0" y="18">{{ 'Label Designer' | translate }}</text>
            </g>
        </ng-container>
        <ng-container *ngSwitchCase="'redo'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'undo'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'check'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
        </ng-container>
        <ng-container *ngSwitchCase="'close'">
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'drag-handle'">
            <defs><path id="a" d="M0 0h24v24H0V0z" /></defs>
            <clipPath id="b">
                <use xlink:href="#a" overflow="visible" />
            </clipPath>
            <path clip-path="url(#b)" d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
        </ng-container>
        <ng-container *ngSwitchCase="'move'">
            <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'left'">
            <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
            <path fill="none" d="M0 0h24v24H0z" />
        </ng-container>
        <ng-container *ngSwitchCase="'right'">
            <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
            <path fill="none" d="M0 0h24v24H0z" />
        </ng-container>
        <ng-container *ngSwitchCase="'align-center'">
            <path d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'align-left'">
            <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'align-right'">
            <path d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'font-italic'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
        </ng-container>
        <ng-container *ngSwitchCase="'font-bold'">
            <path
                d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'font-underline'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'save'">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'load'">
            <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'up'">
            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'down'">
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
            <path
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
        <ng-container *ngSwitchCase="'resize'">
            <defs><path id="a" d="M0 0h24v24H0z" /></defs>
            <clipPath id="b">
                <use xlink:href="#a" overflow="visible" />
            </clipPath>
            <path
                clip-path="url(#b)"
                d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"
            />
            <path clip-path="url(#b)" fill="none" d="M0 0h24v24H0z" />
        </ng-container>
        <ng-container *ngSwitchCase="'refresh'">
            <path
                d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </ng-container>
    </ng-container>
</svg>
