import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions
} from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly iconRegistry: MatIconRegistry
  ) {
    this.iconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
          case 'heroicons_solid':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/heroIcon/${name}.svg`
            );
          case 'action':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/heroIcon/actions/${name}.svg`
            );

          // case 'mat_solid':
          //   return this.domSanitizer.bypassSecurityTrustResourceUrl(
          //     'assets/icons/material-solid.svg'
          //   );
          // case 'feather':
          //   return this.domSanitizer.bypassSecurityTrustResourceUrl(
          //     'assets/icons/feather.svg'
          //   );

          // case 'heroicons_outline':
          //   return this.domSanitizer.bypassSecurityTrustResourceUrl(
          //     'assets/icons/heroicons-outline.svg'
          //   );

          // case 'heroicons_solid':
          //   return this.domSanitizer.bypassSecurityTrustResourceUrl(
          //     'assets/icons/heroicons-solid.svg'
          //   );

          default:
            return null;
        }
      }
    );

    this.iconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-outline.svg'
      )
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/material-solid.svg'
      )
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'feather',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/feather.svg'
      )
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-outline.svg'
      )
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/heroicons-solid.svg'
      )
    );
  }
}
