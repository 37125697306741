import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { VexConfigService } from '@vex/config/vex-config.service';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { firstValueFrom, Observable, of } from 'rxjs';
import { NavigationComponent } from '../navigation/navigation.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { NavigationItemComponent } from '../navigation/navigation-item/navigation-item.component';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavigationItem } from '../../../core/navigation/navigation-item.interface';
import { checkRouterChildsData } from '@vex/utils/check-router-childs-data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { CartManager } from '../cart/cart-manager';
import { CartService } from '../cart/cart.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authentication.service';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    RouterLink,
    MatMenuModule,
    NgFor,
    NavigationItemComponent,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    NavigationComponent,
    AsyncPipe,
    MatSidenavModule,
    MatBadgeModule,
    SharedModule
  ]
})
export class ToolbarComponent implements OnInit {
  @HostBinding('class.shadow-b')
  showShadow: boolean = false;
  cartItemCountss: number = 0;
  navigationItems$: Observable<NavigationItem[]> =
    this.navigationService.items$;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );
  title$: Observable<string> = this.configService.select(
    (config) => config.sidenav.title
  );

  isDesktop$: Observable<boolean> = this.layoutService.isDesktop$;
  megaMenuOpen$: Observable<boolean> = of(false);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  @ViewChild('drawer') drawer: MatDrawer;
  userRole: string;
  showCart: boolean = false;
  programList$: Observable<any[]> = this.navigationService.programList$;
  showProgram: boolean = false;
  programInfo: any;
  _cartItemCount: any;
  currentUrl: string;
  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
    private readonly popoverService: VexPopoverService,
    private readonly router: Router,
    private cartManager: CartManager,
    private cartService: CartService,
    public checkUser: UserAuthenticationService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    let role = localStorage.getItem('role');
    this.userRole =
      role == 'ROLE_ADMIN' || role == 'ROLE_ADMIN_STAFF' ? 'admin' : 'subadmin';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.showShadow = checkRouterChildsData(
          this.router.routerState.root.snapshot,
          (data) => data.toolbarShadowEnabled ?? false
        );
      });

    this.onShowCart();
    this.onShowProgram();

    this.programInfo = await firstValueFrom(this.dataService.getUserData());
    this._cartItemCount = this.cartItemCount();
    this.cdr.detectChanges();
    this.dataService
      .getProgramCartObservable()
      .pipe(
        distinctUntilChanged(),
        filter((value) => value === true),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(async (res) => {
        if (res) {
          this.programInfo = await firstValueFrom(
            this.dataService.getUserData()
          );
          this._cartItemCount = this.cartItemCount();
          this.cdr.detectChanges();
        }
      });

    this.dataService.getProgramCartCountObservable().subscribe(async (res) => {
      if (res) {
        this.programInfo = await firstValueFrom(this.dataService.getUserData());
        this._cartItemCount = this.cartItemCount();
        this.cdr.detectChanges();
      }
    });
  }

  openCart(): void {
    this.layoutService.openCart();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  cartItemCount() {
    // if (
    //   !this.programInfo?.isPaid &&
    //   this.programInfo?.isFinalized &&
    //   !this.programInfo?.isOrdered
    // ) {
    //   return 1;
    // } else {
    return this.cartService.cartItemCount;
    // }
  }
  onShowCart() {
    if (!this.checkUser.checkUser()) {
      const checkUrl = (url: string) => {
        this.showCart = url.includes('/program');
      };

      // Initial check
      checkUrl(this.router.url);

      // Subscribe to router events
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          checkUrl(event.url);
        }
      });
    }
  }

  onShowProgram() {
    if (this.checkUser.checkUser()) {
      const checkUrl = (url: string) => {
        let rotesLIst = [
          'license-agreement',
          'formulation',
          'sample',
          'audit',
          'payments',
          'quotations',
          'invoices',
          'brand',
          'product-label-list',
          'emailBuilder',
          'company-dashboard',
          'supplier_declarations'
        ];
        //hiding the program dropdown
        let routesListHideProgramDropdown = [
          'company-dashboard/company-overview',
          'company/company-dashboard/formulation',
          'company/company-dashboard/sample',
          'company/company-dashboard/brand',
          'company/company-dashboard/brandLogo',
          'company/company-dashboard/invoice',
          'company/company-dashboard/quotation',
          'company/company-dashboard/team',
          'company/company-dashboard/contactInfo',
          'program/add',
          'programs/brands/approve',
          'master-data/program-config/program-dashboard/formulation',
          'master-data/program-config/program-dashboard/sample',
          'master-data/program-config/program-dashboard/brand',
          'product-label-list/product-label-approve'
        ];
        if (routesListHideProgramDropdown.some((i) => url.includes(i))) {
          this.showProgram = false;
          this.cdr.detectChanges();
          return;
        }

        this.showProgram = rotesLIst.some((i) => url.includes(i));
        this.cdr.detectChanges();
      };

      // Initial check
      checkUrl(this.router.url);

      // Subscribe to router events
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          checkUrl(event.url);
        }
      });
    }
    this.cdr.detectChanges();
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.id == o2?.id;
  }
}
